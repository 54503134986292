import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetProjectList } from '~/api/project';
import { apiUpdateMeta } from '~/api/user';
import { USER_META } from '~/const';

const getProjectList = createAsyncThunk('projectList/load', async (data: any) => {
    return await apiGetProjectList(data.offset, data.options, data?.groupId);
});

const updateProjectListOptions = createAsyncThunk(
    'projectList/updateOptions',
    async (options: IMeta) => {
        return await apiUpdateMeta(USER_META.PROJECTS_OPTIONS, options);
    },
);

export { getProjectList, updateProjectListOptions };
