import * as React from 'react';
import styles from './Groups.module.scss';
import Input from '~/components/Form/Input/Input';
import Button from '~/components/Button/Button';
import { BUTTON_SIZE, LOADERS, LOADERS_TYPE } from '~/const';
import classNames from 'classnames';
import { State } from '~/store/reducers';
import { useAppSelector } from '~/store';
import { selectLoader } from '~/store/slices/app/slice';

// const colors = [
//     '#7EA4E7',
//     '#31B9B9',
//     '#805AB5',
//     '#CB428D',
//     '#D34048',
//     '#6D4C41',
//     '#D2822B',
//     '#DDC73C',
//     '#A5C457',
//     '#6CA052',
// ];

interface IFC {
    handleCreate: (title: string) => void;
}

const Form = ({ handleCreate }: IFC) => {
    const [title, setTitle] = React.useState<string>('');
    const isGroupPending = useAppSelector((state) =>
        selectLoader(state, LOADERS.GROUP, LOADERS_TYPE.LOADING),
    );
    // const [_color, setColor] = React.useState<string>(colors[0]);

    return (
        <div className={styles.form}>
            <Input
                withoutFormik={true}
                label="Название"
                className={classNames(styles.bigLabel)}
                defaultValue={title}
                changeHandler={(value: string) => setTitle(value)}
                enterHandler={(value: string) => handleCreate(value)}
            />
            {/*<div className={styles.colorAvatar}>*/}
            {/*    <div className={classNames(styles.label, styles.bigLabel)}>*/}
            {/*        <label>Цвет или аватар</label>*/}
            {/*    </div>*/}
            {/*    <div className={styles.chooseBlock}>*/}
            {/*        <div className={styles.colors}>*/}
            {/*            {colors.map((color, key) => (*/}
            {/*                <div*/}
            {/*                    className={classNames(styles.color, {*/}
            {/*                        [styles.active]: color === _color,*/}
            {/*                    })}*/}
            {/*                    key={key}*/}
            {/*                    style={{ backgroundColor: color, borderColor: color }}*/}
            {/*                    onClick={() => setColor(colors[key])}*/}
            {/*                ></div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*        <span>или</span>*/}
            {/*        <div className={styles.avatar}>*/}
            {/*            <Add />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={styles.buttons}>
                <Button
                    className={styles.buttonAdd}
                    size={BUTTON_SIZE.BIG}
                    onClick={() => handleCreate(title)}
                    loading={isGroupPending}
                    disabled={title.length < 1}
                >
                    Создать
                </Button>
            </div>
        </div>
    );
};

export default Form;
