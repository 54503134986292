import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    apiCreateProject,
    apiDeleteProject,
    apiGetProject,
    apiGetProjectTags,
    apiUpdateProject,
} from '~/api/project';
import { put } from '~/utils/api';

const getProject = createAsyncThunk('projects/add', async (id: number) => {
    return await apiGetProject(id);
});

const createProject = createAsyncThunk(
    'projects/create',
    async (data: { groupId: string; title: string }) => {
        return await apiCreateProject({
            group_id: data.groupId,
            title: data.title,
        });
    },
);

const removeProject = createAsyncThunk('projects/delete', async (id: number) => {
    return await apiDeleteProject(id);
});

const updateProject = createAsyncThunk(
    'projects/update',
    async (data: { id: number; data: any }) => {
        return await apiUpdateProject(data.id, data.data);
    },
);
const toggleArchiveProject = createAsyncThunk(
    'projects/toggleArchive',
    async (data: { id: number; activate: boolean }) => {
        return await put(`projects/${data.id}/${data.activate ? 'unarchive' : 'archive'}`);
    },
);

const getProjectTags = createAsyncThunk('projects/loadTAgs', async (id: number) => {
    return await apiGetProjectTags(id);
});

export {
    getProject,
    toggleArchiveProject,
    updateProject,
    createProject,
    removeProject,
    getProjectTags,
};
