import * as React from 'react';
import styles from './Sprints.module.scss';
import classNames from 'classnames';
import TaskCard from '~/components/Cards/Task/TaskCard';
import Scroll from '~/components/Scroll/Scroll';
import { LOADERS, LOADERS_TYPE } from '~/const';
import { getTasksByIds } from '~/utils/utils';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DROPPABLE_ID } from '~/containers/Projects/Sprints/Sprints';
import Circle from '~/components/Loader/Circle/Circle';
import store, { useAppSelector } from '~/store';
import { selectLoader } from '~/store/slices/app/slice';
import { selectTasks } from '~/store/slices/task/slice';
import { loadBacklogIds } from '~/store/slices/sprint/reducers';
import { selectBacklogIds, selectBacklogTotalCount } from '~/store/slices/sprint/slice';

interface IFC {
    sprintId: number;
    projectId: number;
}

const SprintBacklog = ({ sprintId, projectId }: IFC) => {
    const tasks = useAppSelector((state) => selectTasks(state));
    const isSprintBacklogAdditionalLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.SPRINT_BACKLOG_LIST, LOADERS_TYPE.ADDITIONAL_LOADING),
    );
    const isSprintBacklogLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.SPRINT_BACKLOG_LIST, LOADERS_TYPE.LOADING),
    );
    const backlogIds = useAppSelector((state) => selectBacklogIds(state));
    const backlogTotalCount = useAppSelector((state) => selectBacklogTotalCount(state));
    const ref = React.useRef(null);

    const handleInfiniteScroll = () => {
        store.dispatch(loadBacklogIds({ offset: backlogIds.length, projectId }));
    };

    return (
        <Droppable droppableId={DROPPABLE_ID.TASKS_BACKLOG}>
            {(provided) => (
                <div
                    className={styles.backlog}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {isSprintBacklogLoading ? (
                        <Circle className={styles.loader} />
                    ) : (
                        <>
                            <div
                                className={classNames(
                                    'withoutScrollBar',
                                    styles.scrollable,
                                    styles.allTasksContainer,
                                )}
                                ref={ref}
                            >
                                {getTasksByIds(tasks, backlogIds, sprintId).map((task, key) => (
                                    <Draggable draggableId={`${task.id}`} index={key} key={task.id}>
                                        {(provided) => (
                                            <div
                                                className={styles.card}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <TaskCard taskId={task.id} key={key} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {isSprintBacklogAdditionalLoading && (
                                    <div className={styles.additional}>
                                        <Circle />
                                    </div>
                                )}
                            </div>
                            <Scroll
                                element={ref}
                                className={styles.scroll}
                                infiniteScroll={handleInfiniteScroll}
                                currentCount={backlogIds.length}
                                totalCount={backlogTotalCount}
                            />
                        </>
                    )}
                </div>
            )}
        </Droppable>
    );
};

export default SprintBacklog;
