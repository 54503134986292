import * as React from 'react';
import styles from './Header.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { ReactComponent as Notifications } from '~/assets/icons/notification.svg';
import classNames from 'classnames';
import Avatar from '~/components/User/Avatar';
import { ReactComponent as Chevron } from '~/assets/icons/chevron.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { apiLogout } from '~/api/user';
import { useOnClickOutside } from 'usehooks-ts';
import { ReactComponent as Play } from '~/assets/icons/play.svg';
import Group from '~/containers/Layout/Header/Group';
import { ReactComponent as Burger } from '~/assets/icons/burger.svg';
import { ReactComponent as Tasks } from '~/assets/icons/tasks.svg';
import { ReactComponent as Users } from '~/assets/icons/users.svg';
import { ReactComponent as Projects } from '~/assets/icons/projects.svg';
import store, { useAppSelector } from '~/store';
import { selectNotificationVisible, changeVisibleNotification } from '~/store/slices/app/slice';
import { selectNotificationsCount } from '~/store/slices/notification/slice';
import { clearAccount, selectAccount, selectCurrentTask } from '~/store/slices/account/slice';
import { selectTask } from '~/store/slices/task/slice';

const Header = () => {
    const ref = React.useRef(null);
    const { t } = useTranslation();
    /** @deprecated */
    const dispatch = useDispatch();
    const notificationVisible = useAppSelector((state) => selectNotificationVisible(state));
    const notificationsCount = useAppSelector((state) => selectNotificationsCount(state));
    const currentTask = useAppSelector((state) => selectCurrentTask(state));
    const task = useAppSelector((state) => selectTask(state, currentTask?.taskId ?? 0));
    const account = useAppSelector((state) => selectAccount(state));

    const [drop, setDrop] = React.useState(false);

    const logout = () => {
        apiLogout().then(() => {
            store.dispatch(clearAccount());
        });
    };

    useOnClickOutside(ref, () => setDrop(false));

    return (
        <header className={styles.header}>
            <div className={styles.burger}>
                <Burger />
            </div>
            <Group />
            <div className={styles.nav}>
                <NavLink
                    to={urlsMap.taskList}
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    <Tasks />
                    {t('Задачи')}
                </NavLink>
                <NavLink
                    to={urlsMap.projectList}
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    <Projects />
                    {t('Проекты')}
                </NavLink>
                {/*<NavLink to={urlsMap.statisticList}>{t('Статистика')}</NavLink>*/}
                <NavLink
                    to={urlsMap.userList}
                    className={({ isActive }) => (isActive ? styles.active : '')}
                >
                    <Users />
                    {t('Пользователи')}
                </NavLink>
            </div>
            {currentTask && (
                <Link to={`${urlsMap.taskList}/${currentTask.taskId}`}>
                    <div className={styles.currentTask}>
                        <Play />
                        <span className={styles.task}>{task?.title}</span>
                    </div>
                </Link>
            )}
            <div
                className={classNames(styles.notifications, {
                    [styles.newNotifications]: notificationsCount > 0,
                })}
                onClick={() => {
                    dispatch(changeVisibleNotification(!notificationVisible));
                }}
            >
                <Notifications />
                <div
                    className={classNames(styles.counter, {
                        [styles.visible]: notificationsCount > 0,
                    })}
                >
                    {notificationsCount}
                </div>
            </div>
            <div
                className={classNames(styles.profile, {
                    [styles.drop]: drop,
                })}
                onClick={() => setDrop(!drop)}
                ref={ref}
            >
                <Avatar avatar={{ avatar: account.avatar, name: account.name }} />
                <span className={styles.profileName}>{account.name}</span>
                <Chevron />
                <div className={classNames(styles.profileDrop, 'profileDrop')}>
                    <div>
                        <Link to={`${urlsMap.userList}/${account.id}`}>{t('Мой профиль')}</Link>
                    </div>
                    <div>
                        <Link to={urlsMap.online}>{t('Онлайн')}</Link>
                    </div>
                    <div onClick={logout}>{t('Выйти')}</div>
                </div>
            </div>
        </header>
    );
};

export default Header;
