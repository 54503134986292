import * as React from 'react';
import styles from './Toast.module.scss';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { TOAST } from '~/const';
import { ReactComponent as Check } from '~/assets/icons/check.svg';
import { ReactComponent as Cross } from '~/assets/icons/cross.svg';
import { ReactComponent as System } from '~/assets/icons/info-system.svg';
import { ReactComponent as Notification } from '~/assets/icons/notification.svg';
import MarkdownReader from '~/components/Markdown/Reader';
import { useAppSelector } from '~/store';
import { selectToastId, removeToast } from '~/store/slices/app/slice';

interface IFC {
    id?: string;
}

const Toast = ({ id }: IFC) => {
    const [animation, setAnimation] = React.useState<boolean>(false);
    const [remove, setRemove] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const toast = useAppSelector((state) => selectToastId(state, id));

    if (!toast) return null;

    const renderIcon = () => {
        switch (toast.type) {
            case TOAST.SUCCESS:
                return <Check />;
            case TOAST.ERROR:
                return <Cross />;
            case TOAST.NOTIFICATION:
                return <Notification />;
            case TOAST.SYSTEM:
                return <System />;
        }
    };

    React.useEffect(() => {
        const timeOut = setTimeout(() => {
            setAnimation(true);
        }, 50);

        return () => {
            clearTimeout(timeOut);
        };
    }, [toast]);

    const timer = setTimeout(() => {
        if (remove) return;
        handleRemove();
        clearTimeout(timer);
    }, toast.timer ?? 5000);

    const handleRemove = () => {
        setAnimation(false);
        setRemove(true);
        const timeOut = setTimeout(() => {
            dispatch(removeToast(id));
            clearTimeout(timeOut);
        }, 400);
    };

    return (
        <div
            className={classNames(styles.toast, [styles[toast.type]], {
                [styles.animation]: animation,
            })}
            onClick={handleRemove}
        >
            <div className={styles.icon}>{renderIcon()}</div>
            <div className={styles.content}>
                <div className={styles.title}>
                    <MarkdownReader content={toast.title} />
                </div>
                {!!toast.text && (
                    <div className={styles.text}>
                        <MarkdownReader content={toast.text} />
                    </div>
                )}
            </div>
            <div
                className={styles.timeBar}
                style={{ animationDuration: `${(toast.timer ?? 5000) / 1000}s` }}
            />
        </div>
    );
};

export default Toast;
