import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetMeta, apiGetUserList, apiUpdateMeta } from '~/api/user';
import { USER_META } from '~/const';

const getUserList = createAsyncThunk('userList/load', async (data: any) => {
    return await apiGetUserList(data.offset, data.options, data?.groupId);
});

const loadUserListConfig = createAsyncThunk('userList/config', async () => {
    return await apiGetMeta(USER_META.TASKS_CONFIG);
});

const loadUserListOptions = createAsyncThunk('userList/options', async () => {
    return await apiGetMeta(USER_META.TASKS_OPTIONS);
});

const updateUserListOptions = createAsyncThunk('userList/updateOptions', async (options: IMeta) => {
    return await apiUpdateMeta(USER_META.USERS_OPTIONS, options);
});

export { getUserList, loadUserListConfig, loadUserListOptions, updateUserListOptions };
