import * as React from 'react';
import styles from './ConfirmEmail.module.scss';
import { LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import { apiConfirmEmail, apiResendEmailConfirmation, apiSendConfirmEmail } from '~/api/user';
import { deleteMascot, getNoun } from '~/utils/utils';
import PinInput from 'react-pin-input';
import { setTokens } from '~/utils/storage';
import { ReactComponent as Close } from '~/assets/icons/cross.svg';
import { useNavigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { addToast, updateLoader } from '~/store/slices/app/slice';
import store, { useAppSelector } from '~/store';
import { changeIsEmailValid, selectAccount } from '~/store/slices/account/slice';
import { selectUsers } from '~/store/slices/user/slice';
import { getAccount } from '~/store/slices/account/reducers';

const ConfirmEmail = () => {
    const account = useAppSelector((state) => selectAccount(state));
    const users = useAppSelector((state) => selectUsers(state));
    const navigate = useNavigate();
    const urlParams = new URL(window.location.href).search;
    const code = urlParams.split('code=')[1]?.slice(0, 6);
    const email = decodeURIComponent(urlParams.split('email=')[1]);
    const [timer, setTimer] = React.useState(0);
    const isAuthorized = account?.id;
    const [formError, setFormError] = React.useState<string | null>(null);

    React.useEffect(() => {
        deleteMascot();
        if (code) {
            handleComplete(code);
        }
        if (account?.is_email_migration) {
            handleRepeatSend();
        }
    }, []);

    const closeHandler = () => {
        store.dispatch(changeIsEmailValid(true));
        navigate(urlsMap.index);
    };

    const handleComplete = (code: string) => {
        store.dispatch(
            updateLoader({
                loaderGroup: LOADERS.CONFIRM_EMAIL,
                loaderName: LOADERS_TYPE.LOADING,
                state: true,
            }),
        );

        apiConfirmEmail({
            code,
            email: users[account?.id || 0]?.email || account?.email || email || '',
        })
            .then(async (res) => {
                if (isAuthorized) {
                    setTokens(res.accessToken, res.refreshToken, res.socket);
                    store.dispatch(
                        addToast({
                            type: TOAST.SUCCESS,
                            title: 'Адрес электронной почты подтвержден',
                            timer: 3000,
                        }),
                    );
                    closeHandler();
                } else {
                    setTokens(res.accessToken, res.refreshToken, res.socket);
                    store.dispatch(getAccount());
                    closeHandler();
                }
            })
            .catch((error) => {
                setFormError(error);
            })
            .finally(() => {
                store.dispatch(
                    updateLoader({
                        loaderGroup: LOADERS.CONFIRM_EMAIL,
                        loaderName: LOADERS_TYPE.LOADING,
                        state: false,
                    }),
                );
            });
    };

    const handleRepeatSend = () => {
        store.dispatch(
            updateLoader({
                loaderGroup: LOADERS.CONFIRM_EMAIL,
                loaderName: LOADERS_TYPE.LOADING,
                state: true,
            }),
        );

        const innerFinally = () => {
            store.dispatch(
                updateLoader({
                    loaderGroup: LOADERS.CONFIRM_EMAIL,
                    loaderName: LOADERS_TYPE.LOADING,
                    state: false,
                }),
            );

            let seconds = 30;
            setTimer(seconds);

            const interval = setInterval(() => {
                seconds--;
                if (!!seconds) {
                    setTimer(seconds);
                } else {
                    setTimer(seconds);
                    clearInterval(interval);
                }
            }, 1000);
        };

        if (isAuthorized) {
            apiSendConfirmEmail()
                .catch((error) => setFormError(error.msg))
                .finally(() => innerFinally());
        } else {
            apiResendEmailConfirmation({ email })
                .catch((error) => setFormError(error.msg))
                .finally(() => innerFinally());
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title}>Подтверждение электронной почты</div>
                <div className={styles.subTitle}>
                    Пожалуйста, проверьте вашу почту, мы выслали код подтверждения.
                </div>
                <div className={styles.form}>
                    <div className={styles.code}>
                        <PinInput
                            length={6}
                            onComplete={(value) => handleComplete(value)}
                            initialValue={code}
                            type={'custom'}
                            onChange={() => setFormError(null)}
                        />
                    </div>
                    {formError && <div className={styles.error}>{formError}</div>}
                    {!timer ? (
                        <div className={styles.repeatSend}>
                            Если вы не получили письмо, мы можем{' '}
                            <p onClick={handleRepeatSend}>выслать код повторно</p>
                        </div>
                    ) : (
                        <div className={styles.repeatSendTimer}>
                            Запросить код ещё раз можно через {timer}{' '}
                            {getNoun(timer, 'секунду', 'секунды', 'секунд')}
                        </div>
                    )}
                </div>
            </div>
            {isAuthorized && (
                <div className={styles.close} onClick={closeHandler}>
                    <Close />
                </div>
            )}
        </div>
    );
};

export default ConfirmEmail;
