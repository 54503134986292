import * as React from 'react';
import styles from './Layout.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '~/containers/Layout/Header/Header';
import Aside from '~/containers/Layout/Aside/Aside';
import { socket } from '~/ws/socket';
import TracksTime from '~/containers/Layout/TracksTime/TracksTime';
import { POPUPS_NAME } from '~/components/Popup/Manager';
import { POPUP_WRAPPER } from '~/components/Popup/Popup';
import { META, TOAST } from '~/const';
import { prepareApp } from '~/preloaders/appPreloader';
import { prepareEntities } from '~/preloaders/entitiesPreloader';
import store from '~/store';
import { Helmet } from 'react-helmet';
import favicon from '~/assets/icons/favicon.svg';
import faviconPlay from '~/assets/icons/favicon-play.svg';
import { deleteMascot } from '~/utils/utils';
import moment from 'moment';
import { getTracksUserDay } from '~/utils/utils';
import LineLoader from '~/components/Loader/Line/Line';
import Notifications from '~/containers/Layout/Notifications/Notifications';
import { apiAcceptInvite as apiAcceptInviteGroup } from '~/api/user';
import { apiAcceptInvite as apiAcceptInviteProject } from '~/api/project';
import { urlsMap } from '~/utils/urls';
import { useAppSelector } from '~/store';
import { selectMeta, selectNotificationVisible, setPopup } from '~/store/slices/app/slice';
import { addToast } from '~/store/slices/app/slice';
import {
    increaseWorkedTime,
    removeUserProjectInvite,
    selectAccount,
    selectCurrentTask,
    selectCurrentUserId,
} from '~/store/slices/account/slice';
import { selectTasks } from '~/store/slices/task/slice';
import { shallowEqual } from 'react-redux';

const Layout = () => {
    const navigate = useNavigate();
    const notificationVisible = useAppSelector(
        (state) => selectNotificationVisible(state),
        shallowEqual,
    );
    const currentTask = useAppSelector((state) => selectCurrentTask(state), shallowEqual);
    const accountId = useAppSelector((state) => selectCurrentUserId(state), shallowEqual);
    const account = useAppSelector((state) => selectAccount(state), shallowEqual);
    const isPasswordSet = useAppSelector(
        (state) => selectMeta(state, META.PASSWORD_SET),
        shallowEqual,
    );
    const currentGroup = useAppSelector(
        (state) => selectMeta(state, META.CURRENT_GROUP),
        shallowEqual,
    );
    const tasks = useAppSelector((state) => selectTasks(state), shallowEqual);
    const urlParams = new URL(window.location.href);

    React.useEffect(() => {
        prepareApp();
    }, []);

    React.useEffect(() => {
        if (account?.is_password_empty === true && !isPasswordSet) {
            store.dispatch(
                setPopup({
                    name: POPUPS_NAME.NEW_PASSWORD,
                    wrapper: POPUP_WRAPPER.WHITE,
                    withoutClose: true,
                }),
            );
            deleteMascot();
        } else if (account.groups?.[0]?.is_invite && currentGroup === null) {
            const groupId = Number(urlParams.pathname.split('/')?.[3]);

            if (groupId) {
                apiAcceptInviteGroup(groupId).then(() => {
                    //store.dispatch(setMeta(META.CURRENT_GROUP, groupId));
                    // apiGetGroup(groupId).then((res) => {
                    //     store.dispatch(
                    //         _updateGroup({ ...res.group, is_invite: INVITATIONS.NOT_INVITED }),
                    //     );
                    // });
                });
            } else {
                store.dispatch(
                    setPopup({
                        name: POPUPS_NAME.ACCEPT_GROUP,
                        data: { group: account.groups[0] },
                        wrapper: POPUP_WRAPPER.FILTER,
                    }),
                );
            }

            deleteMascot();
        } else if (!!account.projects_invites.length) {
            const invite = account.projects_invites[0];
            const alreadyAccept = urlParams.pathname.split('/')?.[3];

            if (alreadyAccept) {
                apiAcceptInviteProject(invite.project_id).then(async () => {
                    store.dispatch(removeUserProjectInvite(invite));
                    navigate(`${urlsMap.projectList}/${invite.project_id}`);
                    store.dispatch(
                        addToast({
                            type: TOAST.SUCCESS,
                            title: `Приглашение принято`,
                            timer: 5000,
                        }),
                    );
                });
            } else {
                store.dispatch(
                    setPopup({
                        name: POPUPS_NAME.ACCEPT_PROJECT,
                        data: { invite },
                        wrapper: POPUP_WRAPPER.FILTER,
                        withoutClose: true,
                    }),
                );
            }

            deleteMascot();
        } else if (currentGroup !== undefined) {
            prepareEntities();

            if (!account.groups?.length) {
                store.dispatch(
                    setPopup({
                        name: POPUPS_NAME.CREATE_GROUP,
                        data: undefined,
                        wrapper: POPUP_WRAPPER.WHITE,
                    }),
                );
            }
        }
    }, [currentGroup, isPasswordSet, account.projects_invites]);

    React.useEffect(() => {
        if (currentTask) {
            const totalTime = setInterval(() => {
                store.dispatch(increaseWorkedTime());
            }, 60000);

            return () => clearInterval(totalTime);
        }
    }, [currentTask]);

    React.useEffect(() => {
        accountId && socket(accountId);
    }, [accountId]);

    const timeZone = moment().utcOffset() / 60 - 3;

    const dayData = getTracksUserDay(
        moment().subtract(timeZone, 'hours'),
        tasks,
        accountId,
        accountId,
    );

    return (
        <div className={styles.layout}>
            <Helmet>
                <link
                    id="favicon"
                    rel="icon"
                    href={(currentTask ? faviconPlay : favicon) as string}
                    type="image/x-icon"
                />
            </Helmet>
            {account.calendarOn && (
                <TracksTime
                    className={styles.layoutTrackTimer}
                    dayData={dayData}
                    day={moment().subtract(timeZone, 'hours')}
                />
            )}
            <Header />
            <div className={styles.flex}>
                <Aside />
                <React.Suspense fallback={<LineLoader />}>
                    <Outlet />
                </React.Suspense>
                {notificationVisible && <Notifications />}
            </div>
        </div>
    );
};

export default Layout;
