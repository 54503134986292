import * as React from 'react';
import styles from '~/containers/Layout/Header/Header.module.scss';
import { ReactComponent as Chevron } from '~/assets/icons/chevron.svg';
import classNames from 'classnames';
import { ReactComponent as AllGroups } from '~/assets/icons/all-groups.svg';
import { ReactComponent as Add } from '~/assets/icons/add.svg';
import { useOnClickOutside } from 'usehooks-ts';
import Avatar from '~/components/User/Avatar';
import { BUTTON_COLOR, BUTTON_SIZE, LOADERS, LOADERS_TYPE, META } from '~/const';
import Button from '~/components/Button/Button';
import { POPUPS_NAME } from '~/components/Popup/Manager';
import { POPUP_WRAPPER } from '~/components/Popup/Popup';
import Input from '~/components/Form/Input/Input';
import { apiGetGroup } from '~/api/user';
import store, { useAppSelector } from '~/store';
import { ReactComponent as Attention } from '~/assets/icons/attention.svg';
import CircleLoader from '~/components/Loader/Circle/Circle';
import { useLocation, useNavigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import {
    selectLoader,
    selectLoaders,
    selectMeta,
    selectSubLoaders,
    setPopup,
    updateLoader,
} from '~/store/slices/app/slice';
import { selectAccount, updateUserGroup } from '~/store/slices/account/slice';
import { setMeta } from '~/store/slices/app/reducers';
import { selectUserListTotalCount } from '~/store/slices/userList/slice';
import { updateGroup } from '~/store/slices/account/reducers';

const plural = require('plural-ru');

const Group = () => {
    const ref = React.useRef(null);
    const isGroupListLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.GROUP_LIST, LOADERS_TYPE.LOADING),
    );
    const isGroupsLoading = useAppSelector((state) => selectSubLoaders(state, LOADERS.GROUP_LIST));
    const usersCount = useAppSelector((state) => selectUserListTotalCount(state));
    const location = useLocation();
    const navigate = useNavigate();

    const account = useAppSelector((state) => selectAccount(state));
    const currentGroup = useAppSelector((state) => selectMeta(state, META.CURRENT_GROUP));
    const groups = account.groups;
    const [drop, setDrop] = React.useState(false);
    const activeGroup = account?.groups?.find((group) => group.id === currentGroup);
    const canEdit = activeGroup?.group_role_id === 1;
    const hasInvitations = account.groups?.find((group) => group.is_invite);

    useOnClickOutside(ref, () => setDrop(false));

    const toggleHandler = (group: IGroup | null) => {
        if (group?.is_invite) {
            store.dispatch(
                setPopup({
                    name: POPUPS_NAME.ACCEPT_GROUP,
                    data: { group },
                    wrapper: POPUP_WRAPPER.FILTER,
                }),
            );
        } else {
            store.dispatch(
                updateLoader({
                    loaderGroup: LOADERS.GROUP_LIST,
                    loaderName: group?.id || LOADERS_TYPE.LOADING,
                    state: true,
                }),
            );
            store.dispatch(
                setMeta({
                    key: META.CURRENT_GROUP,
                    data: { [META.CURRENT_GROUP]: group?.id || null },
                }),
            );
            if (group?.id) {
                apiGetGroup(group.id)
                    .then((res) => {
                        store.dispatch(updateUserGroup(res.group));
                    })
                    .finally(() => {
                        store.dispatch(
                            updateLoader({
                                loaderGroup: LOADERS.GROUP_LIST,
                                loaderName: group?.id,
                                state: false,
                            }),
                        );

                        const pathnames = location.pathname.split('/');
                        if (pathnames.length > 2) {
                            navigate(urlsMap.index + pathnames[1]);
                        }
                    });
            }
        }
    };

    const editHandler = (title: string) => {
        if (activeGroup) {
            store.dispatch(updateGroup({ ...activeGroup, title }));
        }
    };

    const deleteHandler = () => {
        store.dispatch(
            setPopup({
                name: POPUPS_NAME.DELETE_GROUP,
                data: { activeGroup },
                wrapper: POPUP_WRAPPER.WHITE,
            }),
        );
    };

    const inviteHandler = () => {
        setDrop(false);
        store.dispatch(
            setPopup({
                name: POPUPS_NAME.GROUP_INVITE,
                data: { groupId: activeGroup?.id },
            }),
        );
    };

    const leaveHandler = () => {
        store.dispatch(
            setPopup({
                name: POPUPS_NAME.LEAVE_GROUP,
                data: { activeGroup },
                wrapper: POPUP_WRAPPER.FILTER,
            }),
        );
    };

    const loading = groups?.map((group) =>
        useAppSelector((state) =>
            selectLoaders(state, [
                { group: LOADERS.GROUP_LIST, name: group.id },
                { group: LOADERS.GROUP, name: LOADERS_TYPE.LOADING },
                { group: LOADERS.TASK_LIST, name: LOADERS_TYPE.LOADING },
                { group: LOADERS.USER_LIST, name: LOADERS_TYPE.LOADING },
                { group: LOADERS.PROJECT_LIST, name: LOADERS_TYPE.LOADING },
            ]),
        ),
    );

    return (
        <div
            className={classNames({
                [styles.groupDrop]: drop,
            })}
            ref={ref}
        >
            <div className={styles.group} onClick={() => setDrop(!drop)}>
                {activeGroup ? (
                    <Avatar avatar={{ name: activeGroup.title }} />
                ) : (
                    <div className={styles.avatar} style={{ backgroundColor: 'transparent' }}>
                        <AllGroups />
                    </div>
                )}
                {hasInvitations && (
                    <div className={styles.attention}>
                        <Attention />
                    </div>
                )}
                <div className={styles.chevron}>
                    <Chevron />
                </div>
            </div>
            <div className={styles.groupDropdown}>
                <div className={styles.groups}>
                    <div
                        className={classNames({
                            [styles.allActive]: !activeGroup,
                            ['disabled']: loading,
                        })}
                        onClick={() => toggleHandler(null)}
                    >
                        {isGroupListLoading ? (
                            <CircleLoader className={styles.groupListLoader} />
                        ) : (
                            <AllGroups />
                        )}
                    </div>
                    {groups?.map((group) => (
                        <div
                            onClick={() => toggleHandler(group)}
                            className={classNames({
                                [styles.active]: activeGroup?.id === group.id,
                                ['disabled']: loading,
                            })}
                            key={group.id}
                        >
                            {isGroupsLoading[group.id] ? (
                                <CircleLoader className={styles.groupListLoader} />
                            ) : (
                                <Avatar
                                    avatar={{ name: group.title }}
                                    attention={group.is_invite}
                                />
                            )}
                        </div>
                    ))}
                    <div
                        onClick={() =>
                            store.dispatch(
                                setPopup({
                                    name: POPUPS_NAME.CREATE_GROUP,
                                    data: undefined,
                                    wrapper: POPUP_WRAPPER.WHITE,
                                }),
                            )
                        }
                    >
                        <Add />
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.infoHeader}>
                        {loading ? (
                            <div className={styles.loader}>
                                <CircleLoader />
                            </div>
                        ) : activeGroup ? (
                            <Avatar avatar={{ name: activeGroup.title }} />
                        ) : (
                            <AllGroups />
                        )}
                        <div className={styles.infoTitle}>
                            {activeGroup ? (
                                <Input
                                    defaultValue={activeGroup.title}
                                    changeHandler={(value: string) => editHandler(value)}
                                    disabled={!canEdit}
                                    debounce={true}
                                    withoutFormik={true}
                                    withoutBorder={true}
                                />
                            ) : (
                                'Все группы'
                            )}
                            <div className={styles.infoSubTitle}>
                                {usersCount +
                                    ' ' +
                                    plural(
                                        usersCount,
                                        'пользователь',
                                        'пользователя',
                                        'пользователей',
                                    )}
                            </div>
                        </div>
                    </div>
                    {canEdit ? (
                        <div className={styles.buttons}>
                            <Button size={BUTTON_SIZE.SMALL} onClick={inviteHandler}>
                                Пригласить пользователей
                            </Button>
                            <Button
                                size={BUTTON_SIZE.SMALL}
                                color={BUTTON_COLOR.RED}
                                onClick={deleteHandler}
                            >
                                Удалить группу
                            </Button>
                        </div>
                    ) : (
                        activeGroup && (
                            <div className={styles.buttons}>
                                <Button
                                    size={BUTTON_SIZE.SMALL}
                                    color={BUTTON_COLOR.RED}
                                    onClick={leaveHandler}
                                >
                                    Покинуть группу
                                </Button>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Group;
