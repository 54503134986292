import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetDictionaries } from '~/api/app';
import { apiUpdateAppMeta } from '~/api/user';

const getDictionaries = createAsyncThunk(
    'app/dictionaries',
    async (groupId: number | undefined) => {
        return await apiGetDictionaries(groupId);
    },
);

const setMeta = createAsyncThunk('app/setMeta', async (data: { key: string; data: IAppMeta }) => {
    return await apiUpdateAppMeta(data.key, data.data);
});

export { getDictionaries, setMeta };
