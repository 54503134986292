import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { useAppSelector } from '~/store';
import { selectAccount } from '~/store/slices/account/slice';

const OtherRoutes = () => {
    const account = useAppSelector((state) => selectAccount(state));
    return account?.id ? <Navigate to={urlsMap.taskList} /> : <Outlet />;
};

export default OtherRoutes;
