import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import { useDispatch } from 'react-redux';
import { apiKickInvite } from '~/api/project';
import { addToast, selectLoader, setPopup } from '~/store/slices/app/slice';
import store, { useAppSelector } from '~/store';
import { update } from '~/store/slices/project/slice';

interface IFC {
    project: IProject;
    user: IUser;
}

const ProjectKick = ({ project, user }: IFC) => {
    const isGroupLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.GROUP, LOADERS_TYPE.LOADING),
    );
    /** @deprecated */
    const dispatch = useDispatch();
    const relations = project?.relations || [];
    const projectId = project.id;

    const closeHandler = () => {
        store.dispatch(setPopup(null));
    };

    const kickHandler = async () => {
        const inviteId = relations.find((relation) => relation.user_id === user.id)?.id;

        if (inviteId) {
            apiKickInvite(projectId, inviteId).then(async () => {
                store.dispatch(
                    update([
                        {
                            ...project,
                            relations: relations.filter((relation) => relation.user_id !== user.id),
                        },
                    ]),
                );
                store.dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: 'Пользователь удален из проекта',
                        timer: 3000,
                    }),
                );
            });
        }

        closeHandler();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Удаление пользователя из проекта</div>
            <p>Вы уверены, что хотите удалить {user.name} из проекта?</p>
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button color={BUTTON_COLOR.RED} onClick={kickHandler} loading={isGroupLoading}>
                    Удалить
                </Button>
            </div>
        </div>
    );
};

export default ProjectKick;
