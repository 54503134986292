import * as React from 'react';
import styles from './TaskCards.module.scss';
import Avatar from '~/components/User/Avatar';
import { getColor, prepareTimeFromMinutes } from '~/utils/utils';
import Priority from '~/components/Icons/Priority/Priority';
import Status from '~/components/Icons/Status/Status';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { PLACE } from '~/const';
import { useAppSelector } from '~/store';
import { selectUser } from '~/store/slices/user/slice';
import { selectSprint } from '~/store/slices/sprint/slice';
import { selectTask } from '~/store/slices/task/slice';

interface IFC {
    taskId: number;
    className?: string;
    withoutStatus?: boolean;
}

const TaskCard = ({ taskId, className, withoutStatus }: IFC) => {
    const task = useAppSelector((state) => selectTask(state, taskId));
    const sprint = useAppSelector((state) => selectSprint(state, task?.sprint_id ?? 0));

    if (!task) {
        return null;
    }

    const executive = useAppSelector((state) => selectUser(state, task?.executive ?? 0));

    return (
        <div
            className={classNames(styles.wrapper, className, {
                [styles.isDelayed]: task.isDelayed,
            })}
        >
            <div className={styles.secondColumn}>
                {!!task?.sprint_id && (
                    <Link
                        to={`${urlsMap.projectList}/${task.project}${urlsMap.processList}/${task.sprint_id}`}
                        className={styles.sprint}
                    >
                        {sprint?.title}
                    </Link>
                )}
                <div className={styles.text}>
                    {!withoutStatus && (
                        <Status
                            statusId={task.status}
                            projectId={task.pid}
                            className={styles.status}
                            classNameTooltip={styles.tooltipStatus}
                            place={PLACE.RIGHT}
                        />
                    )}
                    <Link
                        to={`${urlsMap.taskList}/${task.id}`}
                        className={classNames(styles.title, {
                            [styles.withoutStatus]: !withoutStatus,
                        })}
                    >
                        <span>#{task.id}</span> {task.title}
                    </Link>
                </div>
                {task?.tags && !!task?.tags?.length && (
                    <>
                        {task.tags.map((tag) => (
                            <div className={classNames(styles.tag, getColor(tag.name))}>
                                {tag.name}
                            </div>
                        ))}
                    </>
                )}
            </div>
            <div className={styles.firstColumn}>
                <div
                    className={classNames(styles.date, {
                        [styles.delayed]: task.isDelayed,
                    })}
                >
                    {task.edate}
                </div>
                <div className={styles.difficulty}>{prepareTimeFromMinutes(task.difficulty)}</div>
                <Priority priority={task.priority} className={styles.priority} withZero={true} />
                <Avatar
                    className={styles.avatar}
                    avatar={{ avatar: executive?.avatar, name: executive?.name }}
                />
            </div>
        </div>
    );
};

export default TaskCard;
