import * as React from 'react';
import styles from './Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import { apiKickInvite } from '~/api/user';
import { addToast, selectLoader, setPopup, updateLoader } from '~/store/slices/app/slice';
import store, { useAppSelector } from '~/store';
import { removeUserListId } from '~/store/slices/userList/slice';

interface IFC {
    groupId: number;
    user: IUser;
}

const Kick = ({ groupId, user }: IFC) => {
    const isGroupLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.GROUP, LOADERS_TYPE.LOADING),
    );

    const closeHandler = () => {
        store.dispatch(setPopup(null));
    };

    const kickHandler = () => {
        const inviteId = user.groups.find((group) => group.id === groupId)?.invite_id;

        if (!inviteId) {
            return;
        }

        store.dispatch(
            updateLoader({
                loaderGroup: LOADERS.GROUP,
                loaderName: LOADERS_TYPE.LOADING,
                state: true,
            }),
        );
        apiKickInvite(groupId, inviteId)
            .then(() => {
                store.dispatch(removeUserListId(user.id));
                closeHandler();
                store.dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: 'Пользователь удален из группы',
                        timer: 3000,
                    }),
                );
            })
            .finally(() => {
                store.dispatch(
                    updateLoader({
                        loaderGroup: LOADERS.GROUP,
                        loaderName: LOADERS_TYPE.LOADING,
                        state: false,
                    }),
                );
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Удаление пользователя из группы</div>
            <p>Вы уверены, что хотите удалить {user.name} из группы?</p>
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button color={BUTTON_COLOR.RED} onClick={kickHandler} loading={isGroupLoading}>
                    Удалить
                </Button>
            </div>
        </div>
    );
};

export default Kick;
