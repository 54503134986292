import * as React from 'react';
import styles from './SprintStatus.module.scss';
import classNames from 'classnames';
import Tooltip from '~/components/Tooltip/Tooltip';
import { sprintStatuses } from '~/containers/Projects/Sprints/Sprints';

interface IFC {
    type?: number | string;
    className?: string;
    withoutTooltip?: boolean;
    state?: number;
}

const SprintStatus = ({ type, className, withoutTooltip, state }: IFC) => {
    const strictType = (state + '') as '0' | '1' | '2';
    console.log(state);
    const element = (
        <div className={classNames(styles.type, styles[`type${state}`], className)}></div>
    );

    if (!withoutTooltip) {
        return (
            <Tooltip text={strictType ? sprintStatuses?.[strictType] : 'Неизвестный статус'}>
                {element}
            </Tooltip>
        );
    }

    return element;
};

export default SprintStatus;
