import * as React from 'react';
import styles from './Toasts.module.scss';
import Toast from '~/components/Toast/Toast';
import { useAppSelector } from '~/store';
import { selectToasts } from '~/store/slices/app/slice';

const Toasts = () => {
    const toasts = useAppSelector((state) => selectToasts(state));
    const ids = toasts.filter((toast) => toast.visible).map((toast) => toast.id);

    return (
        <div className={styles.wrapper}>
            {ids.map((id) => <Toast key={id} id={id} />).reverse()}
        </div>
    );
};

export default Toasts;
