import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetTaskList } from '~/api/task';
import { apiGetMeta, apiUpdateMeta } from '~/api/user';
import { USER_META } from '~/const';

const getTaskList = createAsyncThunk(
    'taskList/load',
    async (data: { offset: number; options: IMeta; groupId?: number }) => {
        return await apiGetTaskList(data.offset, data.options, data?.groupId);
    },
);

const loadTaskListConfig = createAsyncThunk('taskList/config', async () => {
    return await apiGetMeta(USER_META.TASKS_CONFIG);
});

const loadTaskListOptions = createAsyncThunk('taskList/options', async () => {
    return await apiGetMeta(USER_META.TASKS_OPTIONS);
});

const updateTaskListOptions = createAsyncThunk('taskList/updateOptions', async (options: IMeta) => {
    return await apiUpdateMeta(USER_META.TASKS_OPTIONS, options);
});

const updateTaskListConfig = createAsyncThunk(
    'taskList/updateConfig',
    async (config: ITableConfig[]) => {
        return await apiUpdateMeta(USER_META.TASKS_CONFIG, config);
    },
);

export {
    getTaskList,
    loadTaskListConfig,
    loadTaskListOptions,
    updateTaskListOptions,
    updateTaskListConfig,
};
