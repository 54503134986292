import * as React from 'react';
import styles from './Delete.module.scss';
import { urlsMap } from '~/utils/urls';
import { BUTTON_COLOR, INPUT_SIZE, TOAST } from '~/const';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Input from '~/components/Form/Input/Input';
import Button from '~/components/Button/Button';
import { strSanitize } from '~/utils/utils';
import { addToast, setPopup } from '~/store/slices/app/slice';
import store from '~/store';
import { removeProject } from '~/store/slices/project/reducers';

interface IFC {
    project: IProject;
}

const DeleteProject = ({ project }: IFC) => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [name, setName] = React.useState<string>('');
    const title = strSanitize(project.title);

    const closeHandler = () => {
        store.dispatch(setPopup(null));
    };

    const handleDelete = () => {
        setLoading(true);
        store
            .dispatch(removeProject(project.id))
            .then(() => {
                navigate(urlsMap.projectList);
                store.dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: `Проект ${project.title} удалён`,
                        timer: 3000,
                    }),
                );
                closeHandler();
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className={styles.content}>
            <div className={classNames('contentHeader', styles.contentHeader)}>
                <h2>Удаление проекта {project.title}</h2>
                <p>Внимание! Вместе с удалением проекта пропадут все задачи.</p>
                <p>Введите «{title}», чтобы подтвердить удаление проекта</p>
            </div>
            <div className={styles.form}>
                <Input
                    withoutFormik={true}
                    label="Название проекта"
                    className={classNames(styles.bigLabel)}
                    size={INPUT_SIZE.BIG}
                    defaultValue={name}
                    changeHandler={(value) => setName(value)}
                    enterHandler={() => {
                        if (name === title) {
                            handleDelete();
                        }
                    }}
                />
                <div className={styles.buttons}>
                    <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                        Отмена
                    </Button>
                    <Button
                        color={BUTTON_COLOR.RED}
                        disabled={name !== title}
                        onClick={handleDelete}
                        loading={loading}
                    >
                        Удалить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteProject;
