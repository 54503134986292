import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetMe, apiUpdateGroup } from '~/api/user';
import { saveFilter } from '~/api/task';

const getAccount = createAsyncThunk('account/dictionaries', async () => {
    return await apiGetMe(true);
});

const updateFilter = createAsyncThunk('account/filters', async (filters: IFilter[]) => {
    return await saveFilter(filters);
});

const updateGroup = createAsyncThunk('account/groups', async (group: IGroup) => {
    return await apiUpdateGroup(group);
});

export { getAccount, updateFilter, updateGroup };
