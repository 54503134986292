import * as React from 'react';
import styles from './Delete.module.scss';
import { urlsMap } from '~/utils/urls';
import { BUTTON_COLOR } from '~/const';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Button from '~/components/Button/Button';
import { setPopup } from '~/store/slices/app/slice';
import store from '~/store';
import { removePage } from '~/store/slices/wiki/reducers';

interface IFC {
    projectId: number;
    page?: IWikiPage;
}

const DeleteWikiPage = ({ projectId, page }: IFC) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const closeHandler = () => {
        store.dispatch(setPopup(null));
    };

    const handleDelete = () => {
        if (page) {
            setLoading(true);

            store
                .dispatch(removePage(page?.id))
                .then(() => {
                    navigate(`${urlsMap.projectList}/${projectId}${urlsMap.wiki}/0`);
                })
                .finally(() => {
                    setLoading(false);
                    closeHandler();
                });
        }
    };

    return (
        <div className={styles.content}>
            <div className={classNames('contentHeader', styles.contentHeader)}>
                <h2>Удаление страницы</h2>
                <p>Вы уверены, что хотите удалить страницу “{page?.title}”?</p>
            </div>
            <div className={styles.form}>
                <div className={styles.buttons}>
                    <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                        Отмена
                    </Button>
                    <Button color={BUTTON_COLOR.RED} onClick={handleDelete} loading={loading}>
                        Удалить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteWikiPage;
