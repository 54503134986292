import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    getTaskList,
    loadTaskListConfig,
    loadTaskListOptions,
    updateTaskListConfig,
} from '~/store/slices/taskList/reducers';
import { ORDER_BY, USER_META } from '~/const';
import { TASKS_FILTERS } from '~/utils/tasks';
import { getAccount } from '~/store/slices/account/reducers';

export const tasksAdapter = createEntityAdapter<ITask>();

export interface ITaskListState {
    loadedIds: number[];
    totalCount: number;
    isCalendarVisible: boolean;
    isLoading: boolean;
    isOptionsLoading: boolean;
    isMoreLoading: boolean;
    config: ITableConfig[];
    options: IMeta;
    offset: number;
    tasksFilterName: string;
}

const initialState: ITaskListState = {
    loadedIds: [],
    totalCount: 0,
    isCalendarVisible: true,
    isLoading: false,
    isOptionsLoading: false,
    isMoreLoading: false,
    config: [],
    options: {
        filters: {
            [TASKS_FILTERS.STATUS]: 'my',
            [TASKS_FILTERS.SEARCH]: '',
            [TASKS_FILTERS.STATUS_ID]: [],
            [TASKS_FILTERS.CREATOR]: [],
            [TASKS_FILTERS.EXECUTIVE]: [],
            [TASKS_FILTERS.PID]: [],
            [TASKS_FILTERS.WDATE]: [],
            [TASKS_FILTERS.TAG]: [],
        },
        orderby: ['priority', ORDER_BY.DESC],
        filterName: '',
    },
    offset: 0,
    tasksFilterName: '',
};

export const taskListSlice = createSlice({
    name: 'taskList',
    initialState,
    reducers: {
        changeOffset(state, action) {
            state.offset = action.payload;
        },
        changeTasksFilterName(state, action) {
            state.tasksFilterName = action.payload;
        },
        changeTotalCount(state, action) {
            state.totalCount = action.payload;
        },
        changeIsCalendarVisible(state, action) {
            state.isCalendarVisible = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTaskList.pending, (state, action) => {
            if (action.meta.arg.offset === 0) {
                state.isLoading = true;
            } else {
                state.isMoreLoading = true;
            }
        });
        builder.addCase(getTaskList.fulfilled, (state, action) => {
            if (action.payload) {
                state.totalCount = action.payload.totalCount;
                state.isLoading = false;
                state.isMoreLoading = false;
                if (action.meta.arg.offset === 0) {
                    state.loadedIds = [...action.payload.rows.map((item: ITask) => item.id)];
                } else {
                    state.loadedIds = [
                        ...state.loadedIds,
                        ...action.payload.rows.map((item: ITask) => item.id),
                    ];
                }
                state.offset = action.meta.arg.offset;
            }
        });
        builder.addCase(loadTaskListConfig.fulfilled, (state, action) => {
            if (action.payload) {
                state.config = action.payload[USER_META.TASKS_CONFIG];
            }
        });
        builder.addCase(loadTaskListOptions.fulfilled, (state, action) => {
            if (action.payload) {
                const savedOptions = action.payload[USER_META.TASKS_OPTIONS];
                state.options = { ...state.options, ...savedOptions };
                state.isCalendarVisible = state.options.calendarVisible || state.isCalendarVisible;
                state.isOptionsLoading = false;
            }
        });
        builder.addCase(getAccount.fulfilled, (state, action) => {
            if (action.payload) {
                const savedOptions = action.payload?.meta[USER_META.TASKS_OPTIONS];
                if (savedOptions) {
                    state.options = { ...state.options, ...savedOptions };
                    state.isCalendarVisible =
                        state.options.calendarVisible || state.isCalendarVisible;
                }
                state.config = action.payload?.meta[USER_META.TASKS_CONFIG];
            }
        });
        builder.addCase(loadTaskListOptions.pending, (state) => {
            state.isOptionsLoading = true;
        });
        builder.addCase(updateTaskListConfig.pending, (state, action) => {
            state.config = [...action.meta.arg];
        });
    },
    selectors: {
        selectTotalCount: (sliceState) => sliceState.totalCount,
        selectIsCalendarVisible: (sliceState) => sliceState.isCalendarVisible,
        selectTaskListIsLoading: (sliceState) => sliceState.isLoading,
        selectTaskListIsMoreLoading: (sliceState) => sliceState.isMoreLoading,
        selectConfig: (sliceState) => sliceState.config,
        selectTaskListOffset: (sliceState) => sliceState.offset,
        selectTaskListIds: (sliceState) => [...sliceState.loadedIds],
        selectTaskListOptions: (sliceState) => sliceState.options,
        selectTaskListOptionsIsLoading: (sliceState) => sliceState.isOptionsLoading,
        selectTaskFilterName: (sliceState) => sliceState.tasksFilterName,
    },
});

export type taskListSlice = {
    [taskListSlice.name]: ReturnType<typeof taskListSlice['reducer']>;
};
export const { changeTotalCount, changeIsCalendarVisible, changeOffset, changeTasksFilterName } =
    taskListSlice.actions;
export const {
    selectTotalCount,
    selectIsCalendarVisible,
    selectConfig,
    selectTaskListIds,
    selectTaskListIsLoading,
    selectTaskListOptions,
    selectTaskListOffset,
    selectTaskListIsMoreLoading,
    selectTaskListOptionsIsLoading,
    selectTaskFilterName,
} = taskListSlice.selectors;
