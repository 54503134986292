import * as React from 'react';
import styles from './Status.module.scss';
import classNames from 'classnames';
import Tooltip from '~/components/Tooltip/Tooltip';
import { PLACE } from '~/const';
import Skeleton, { SKELETON } from '~/components/Skeleton/Skeleton';
import { useAppSelector } from '~/store';
import { selectProject } from '~/store/slices/project/slice';

interface IStatus {
    statusId?: number | string;
    projectId?: number;
    className?: string;
    classNameTooltip?: string;
    withoutTooltip?: boolean;
    withoutProject?: boolean;
    place?: string;
}

const Status = ({
    statusId,
    projectId,
    className,
    withoutTooltip,
    withoutProject,
    place,
    classNameTooltip,
}: IStatus) => {
    if (!statusId || !projectId) return <Skeleton type={SKELETON.STATUS} />;

    const project = useAppSelector((state) => selectProject(state, projectId));

    if (!project) return <Skeleton type={SKELETON.STATUS} />;
    const status = project.statuses?.find((s) => s.id == statusId);

    const element = (
        <div
            className={classNames(
                styles.status,
                `status${
                    typeof status?.icon === 'number' ? status.icon : withoutProject ? statusId : 12
                }`,
                className,
            )}
        />
    );

    if (!withoutTooltip) {
        return (
            <Tooltip
                className={classNameTooltip}
                text={status?.title ?? 'Неизвестный'}
                place={place ? place : PLACE.LEFT}
            >
                {element}
            </Tooltip>
        );
    }

    return element;
};

export default Status;
