import * as React from 'react';
import styles from './NewPassword.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, INPUT_TYPE, LOADERS, LOADERS_TYPE, META, USER_FIELDS } from '~/const';
import Input from '~/components/Form/Input/Input';
import { apiUpdatePassword } from '~/api/user';
import { deleteMascot } from '~/utils/utils';
import store, { useAppSelector } from '~/store';
import { selectLoader, setPopup, updateLoader } from '~/store/slices/app/slice';
import { selectAccount } from '~/store/slices/account/slice';
import { updateUser } from '~/store/slices/user/reducers';
import { setMeta } from '~/store/slices/app/reducers';

const NewPassword = () => {
    const isSetPasswordPending = useAppSelector((state) =>
        selectLoader(state, LOADERS.SET_PASSWORD, LOADERS_TYPE.LOADING),
    );
    const account = useAppSelector((state) => selectAccount(state));
    const needChangeName = account.name === account.email;

    const [name, setName] = React.useState('');
    const [password, setPassword] = React.useState('');

    React.useEffect(() => {
        deleteMascot();
    }, []);

    const handleSave = async () => {
        store.dispatch(
            updateLoader({
                loaderGroup: LOADERS.SET_PASSWORD,
                loaderName: LOADERS_TYPE.LOADING,
                state: true,
            }),
        );
        if (name.length) {
            store.dispatch(
                updateUser({ properties: { [USER_FIELDS.NAME]: name }, userId: account.id ?? 0 }),
            );
        }
        apiUpdatePassword(password)
            .then(() => {
                store.dispatch(setPopup(null));
                store.dispatch(
                    setMeta({ key: META.PASSWORD_SET, data: { [META.PASSWORD_SET]: true } }),
                );
            })
            .finally(() => {
                store.dispatch(
                    updateLoader({
                        loaderGroup: LOADERS.SET_PASSWORD,
                        loaderName: LOADERS_TYPE.LOADING,
                        state: false,
                    }),
                );
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Установите пароль {needChangeName && 'и задайте имя'}
            </div>
            <div className={styles.form}>
                {needChangeName && (
                    <Input
                        withoutFormik={true}
                        defaultValue={name}
                        changeHandler={(newValue: string) => setName(newValue)}
                        label="Имя"
                    />
                )}
                <Input
                    withoutFormik={true}
                    defaultValue={password}
                    changeHandler={(newValue: string) => setPassword(newValue)}
                    label="Пароль*"
                    type={INPUT_TYPE.PASSWORD}
                    enterHandler={handleSave}
                />
                <div className={styles.buttons}>
                    <Button
                        color={BUTTON_COLOR.BLUE}
                        disabled={password.length < 1}
                        onClick={handleSave}
                        loading={isSetPasswordPending}
                    >
                        Сохранить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NewPassword;
