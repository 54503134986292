import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, TOAST } from '~/const';
import classNames from 'classnames';
import { apiAcceptInvite, apiRefuseInvite } from '~/api/project';
import { useNavigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { addToast, setPopup } from '~/store/slices/app/slice';
import store from '~/store';
import { removeUserProjectInvite } from '~/store/slices/account/slice';

interface IFC {
    invite: IProjectInvite;
}

const ProjectAccept = ({ invite }: IFC) => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => {
        store.dispatch(setPopup(null));
    };

    const handleAccept = () => {
        handleClose();
        setLoading(true);
        apiAcceptInvite(invite.project_id)
            .then(async () => {
                store.dispatch(removeUserProjectInvite(invite));
                handleClose();
                navigate(`${urlsMap.projectList}/${invite.project_id}`);
                store.dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: `Приглашение принято`,
                        timer: 5000,
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleRefuse = () => {
        handleClose();
        setLoading(true);
        apiRefuseInvite(invite.project_id)
            .then(() => {
                store.dispatch(removeUserProjectInvite(invite));
                handleClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Вас пригласили в проект {invite?.project?.title}</div>
            <div className={classNames(styles.buttons, styles.buttonsMargin)}>
                <Button color={BUTTON_COLOR.GRAY} loading={loading} onClick={handleRefuse}>
                    Отклонить
                </Button>
                <Button color={BUTTON_COLOR.BLUE} loading={loading} onClick={handleAccept}>
                    Принять
                </Button>
            </div>
        </div>
    );
};

export default ProjectAccept;
