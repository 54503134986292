import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, TOAST } from '~/const';
import { useDispatch } from 'react-redux';
import CreatableSelectField from '~/components/Form/Select/CreatableSelect';
import { apiProjectInvite } from '~/api/project';
import { addToast, selectUsersDictionary, setPopup } from '~/store/slices/app/slice';
import store, { useAppSelector } from '~/store';
import { update } from '~/store/slices/project/slice';

interface IFC {
    project: IProject;
}

const ProjectInvite = ({ project }: IFC) => {
    /** @deprecated **/
    const dispatch = useDispatch();
    const [values, setValues] = React.useState<ISelectOption[]>([]);
    const usersGroup: {
        [value: string]: string;
    } = useAppSelector((state) => selectUsersDictionary(state)) ?? {};

    const prepareUsersId = Object.keys(usersGroup).filter((id) => {
        return (
            !project.users.map((user) => user.id).includes(Number(id)) &&
            !project.relations?.find((relation) => relation.user_id === Number(id))
        );
    });
    const [loading, setLoading] = React.useState(false);

    const closeHandler = () => {
        store.dispatch(setPopup(null));
    };

    const inviteHandler = () => {
        setLoading(true);
        const data = { invited: values.map((value) => value.value) };
        apiProjectInvite(data, project.id)
            .then(async (res) => {
                store.dispatch(
                    update([
                        {
                            ...project,
                            relations: [...(project.relations || []), ...res.relations],
                        },
                    ]),
                );
                closeHandler();
            })
            .catch((error) => {
                dispatch(
                    addToast({
                        type: TOAST.ERROR,
                        title: error,
                        timer: 3000,
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = async (data: any) => {
        setValues(data);
    };

    const handleCreate = async (newValue: string) => {
        const array = [...values];
        array.push({ label: newValue, value: newValue });
        setValues(array);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Приглашение пользователей
                <br />
                {`${!!project.title && 'в проект ' + project.title}`}
            </div>
            <CreatableSelectField
                label={'Добавить сотрудников'}
                placeholder={'Введите имя или email'}
                formatCreateLabel={'Пригласить'}
                classNameWrapper={styles.selectWrapper}
                defaultValue={values}
                options={prepareUsersId.map((id) => {
                    return { value: id, label: usersGroup[id] ?? '' };
                })}
                handleCreate={handleCreate}
                handleChange={handleChange}
            />
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button color={BUTTON_COLOR.BLUE} onClick={inviteHandler} loading={loading}>
                    Пригласить
                </Button>
            </div>
        </div>
    );
};

export default ProjectInvite;
