import { DATE_FORMAT, DATE_FORMAT_HHMM, ORDER_BY, TASK_FIELDS, TASK_TYPES } from '~/const';
import { prepareDateToLocal } from '~/utils/utils';
import { Dispatch } from 'react';
import { add as addUsers } from '~/store/slices/user/slice';
import { update } from '~/store/slices/sprint/slice';
import store from '~/store';

export const tableConfig = [
    { name: TASK_FIELDS.TITLE, title: 'Заголовок', orderby: true, visible: true, disabled: true },
    { name: TASK_FIELDS.TYPE, title: 'Тип', orderby: false, visible: true },
    { name: TASK_FIELDS.PRIORITY, title: 'Приоритет', orderby: true, visible: true },
    { name: TASK_FIELDS.DIFFICULTY, title: 'Осталось времени', orderby: false, visible: true },
    { name: TASK_FIELDS.STATUS, title: 'Статус', orderby: false, visible: true },
    { name: TASK_FIELDS.WDATE, title: 'Запланировано', orderby: true, visible: true },
    { name: TASK_FIELDS.EDATE, title: 'Дата сдачи', orderby: true, visible: true },
    { name: TASK_FIELDS.CREATED_AT, title: 'Дата создания', orderby: true, visible: false },
    { name: TASK_FIELDS.UPDATED_AT, title: 'Дата изменения', orderby: true, visible: false },
];

export const TASKS_FILTERS = {
    STATUS: 'status',
    STATUS_ID: 'statusId',
    SEARCH: 'search',
    CREATOR: 'creator',
    EXECUTIVE: 'executive',
    PID: 'pid',
    WDATE: 'wdate',
    TAG: 'tag',
    EDATE: 'edate',
    TYPE: 'type',
};

export const defaultOptions: IMeta = {
    filters: {
        [TASKS_FILTERS.STATUS]: 'my',
        [TASKS_FILTERS.SEARCH]: '',
        [TASKS_FILTERS.STATUS_ID]: [],
        [TASKS_FILTERS.CREATOR]: [],
        [TASKS_FILTERS.EXECUTIVE]: [],
        [TASKS_FILTERS.PID]: [],
        [TASKS_FILTERS.WDATE]: [],
        [TASKS_FILTERS.TAG]: [],
    },
    orderby: ['priority', ORDER_BY.DESC],
    filterName: '',
};

export const labelColors = [
    'F2D604',
    'DB995B',
    'D56657',
    'BD77D7',
    '2C84B8',
    '00C2E0',
    '50E898',
    'FF78CC',
    '355263',
    'CBD557',
    '8DDEE3',
];

export const prepareTaskFromServer = (task: any, dispatch: Dispatch<any>) => {
    const itAMeet = task.type === TASK_TYPES.MEETS;
    const timeFormat = itAMeet ? DATE_FORMAT_HHMM : DATE_FORMAT;
    task.wdate = prepareDateToLocal(task.wdate, timeFormat, itAMeet);
    task.edate = prepareDateToLocal(task.edate, timeFormat, itAMeet);
    task.created_at = prepareDateToLocal(task.cdate, timeFormat, itAMeet);
    task.updated_at = prepareDateToLocal(task.updated_at, timeFormat, itAMeet);

    if (!!task?.assignees?.length) {
        const assignees = [...task.assignees];
        task.assignees = {};
        assignees.forEach((assignee: any) => {
            dispatch(addUsers([assignee.user]));
            delete assignee['user'];
            task.assignees[assignee.user_id] = assignee;
        });
    }

    if (!!task?.observers?.length) {
        task.observers = task.observers.map((observer: IUser) => {
            dispatch(addUsers([observer]));
            return observer.id;
        });
    }

    if (task?.children) {
        //dispatch(addTasks(task.children));
        task.children = task.children?.map((_task: ITask) => _task.id);
    }

    if (task?.sprint) {
        store.dispatch(update(task.sprint));
    }

    task?.creator && dispatch(addUsers([task.creator]));
    task.creator = task?.creator?.id || task?.creator;

    task?.executive && dispatch(addUsers([task.executive]));
    task.executive = task?.executive?.id || task?.executive;

    //task?.parent && store.dispatch(addTasks([task.parent]));
    task.parent = task?.parent?.id || task?.parent;

    //task?.project && dispatch(addProjects([task.project]));
    task.project = task?.project?.id || task?.project || task?.pid;

    return task;
};

// Use it to filter updated tasks in a list needs refactoring
export const prepareTasks = () => {
    // Collecting data for online filtering
    /*
    const tasks = useAppSelector((state) => selectTasks(state));
    const options = useAppSelector((state) => selectTaskListOptions(state));
    const ids = useAppSelector((state) => selectTaskListIds(state));
    const currentuserId = useAppSelector((state) => selectCurrentUserId(state));

    let preparedTasks = ids;

    const orderbyField = options.orderby?.[0] as OrderFieldsType;
    const orderbySort = options.orderby?.[1];
    const filters = options.filters;

    if (orderbyField && orderbySort) {
        const isDate = [
            TASK_FIELDS.WDATE,
            TASK_FIELDS.EDATE,
            TASK_FIELDS.UPDATED_AT,
            TASK_FIELDS.CREATED_AT,
        ].includes(orderbyField);

        preparedTasks = preparedTasks.sort((a, b) => {
            const taskA = tasks[a];
            const valueA = taskA[orderbyField];

            const taskB = tasks[b];
            const valueB = taskB[orderbyField];

            let innerA: any = valueA;
            let innerB: any = valueB;

            if (isDate) {
                const momentA = moment(valueA, DATE_FORMAT_HHMM);
                const momentB = moment(valueB, DATE_FORMAT_HHMM);
                innerA = momentA.isValid() ? parseInt(momentA.format('X')) : 0;
                innerB = momentB.isValid() ? parseInt(momentB.format('X')) : 0;
            }

            if (orderbySort === ORDER_BY.ASC) {
                return innerA - innerB;
            } else {
                return innerB - innerA;
            }
        });
    }

    const status = filters?.[TASKS_FILTERS.STATUS];
    if (status) {
        if (status === TASK_LIST_STATUS.OPEN || status !== TASK_LIST_STATUS.ALL) {
            preparedTasks = preparedTasks.filter((id) => {
                // const task = useAppSelector((state) => selectTask(state, id));
                // //const project = useAppSelector((state) => selectProject(state, task.pid));
                // const status = project.statuses.find((status) => status.id === task.status);
                // if (!status) return true;
                // return !!status?.is_open;
                return true;
            });
        }

        if (status === 'my' && currentuserId) {
            preparedTasks = preparedTasks.filter((id) => {
                const task = tasks[id];
                return (
                    task.executive === currentuserId ||
                    task.observers?.includes(currentuserId) ||
                    task.assignees?.[currentuserId]
                );
            });
        }
    }

    const search = filters?.[TASKS_FILTERS.SEARCH];

    if (search) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            return task?.title?.toLowerCase().includes((search as string).toLowerCase());
        });
    }

    const creator: string[] = filters?.[TASKS_FILTERS.CREATOR] as string[];
    if (creator?.length) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            return creator.includes(`${task.creator}`);
        });
    }

    const executive: string[] = filters?.[TASKS_FILTERS.EXECUTIVE] as string[];
    if (executive?.length) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            return executive.includes(`${task.executive}`);
        });
    }

    const wdate: string[] = filters?.[TASKS_FILTERS.WDATE] as string[];
    if (wdate?.length) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            if (wdate.includes('today')) {
                if (moment().isSame(moment(task.wdate, DATE_FORMAT_HHMM), 'days')) return true;
            }
            if (wdate.includes('tomorrow')) {
                if (moment().add(1, 'days').isSame(moment(task.wdate, DATE_FORMAT_HHMM), 'days'))
                    return true;
            }
        });
    }

    const statusId: string[] = filters?.[TASKS_FILTERS.STATUS_ID] as string[];
    if (statusId?.length) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            return statusId.includes(`${task.status}`);
        });
    }

    const tag: string[] = filters?.[TASKS_FILTERS.TAG] as string[];
    if (tag?.length) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            return task.tags?.find((innerTag) => tag.includes(innerTag.name));
        });
    }

    const type: string[] = filters?.[TASKS_FILTERS.TYPE] as string[];
    if (type?.length) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            return type.includes(`${task.type}`);
        });
    }

    const pid: string[] = filters?.[TASKS_FILTERS.PID] as string[];
    if (pid?.length) {
        preparedTasks = preparedTasks.filter((id) => {
            const task = tasks[id];
            return pid.includes(`${task.project}`);
        });
    }

    return preparedTasks;
     */
};
