import { get, post } from '~/utils/api';
import { useAppSelector } from '~/store';
import { selectNotificationsCount } from '~/store/slices/notification/slice';

export const apiGetDictionaries = async (groupId: number | undefined) => {
    return await get(`dictionaries?${groupId ? '&group_id=' + groupId : ''}`);
};

export const apiGetTags = async () => await get('tags');

export const apiGetNotifications = async () => {
    const offset = useAppSelector((state) => selectNotificationsCount(state));

    return await get(`notifications?limit=100&offset=${offset}`);
};

export const apiReadNotifications = async (data?: any) => await post('notifications/read', data);

export const apiReadAllNotifications = async () => await post('notifications/readAll');
