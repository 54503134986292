import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getTaskList } from '~/store/slices/taskList/reducers';
import {
    createProject,
    getProject,
    getProjectTags,
    removeProject,
    toggleArchiveProject,
    updateProject,
} from '~/store/slices/project/reducers';
import { prepareProjectFromServer } from '~/utils/projects';
import { getProjectList } from '~/store/slices/projectList/reducers';

export const projectsAdapter = createEntityAdapter<IProject>();

export interface IProjectState {
    loadedIds: number[];
    entities: Record<number, IProject[]>;
}

export const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        ...projectsAdapter.getInitialState(),
        totalCount: 0,
        viewedCount: 0,
    },
    reducers: {
        create: projectsAdapter.setMany,
        add: projectsAdapter.addMany,
        update: projectsAdapter.upsertMany,
        remove: projectsAdapter.removeMany,
        clear: projectsAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(getProject.fulfilled, (state, action) => {
            if (action.payload) {
                projectsAdapter.upsertOne(state, action.payload.project);
            }
        });
        builder.addCase(getTaskList.fulfilled, (state, action) => {
            if (action.payload) {
                const projects = action.payload.rows.map((task: ITask) => task.project);
                projectsAdapter.upsertMany(state, projects);
            }
        });
        builder.addCase(getProjectList.fulfilled, (state, action) => {
            if (action.payload) {
                projectsAdapter.upsertMany(state, action.payload.rows);
            }
        });
        builder.addCase(toggleArchiveProject.fulfilled, (state, action) => {
            projectsAdapter.upsertOne(state, action.payload.project);
        });
        builder.addCase(updateProject.fulfilled, (state, action) => {
            const project = prepareProjectFromServer(action.payload.project);
            projectsAdapter.upsertOne(state, project);
        });
        builder.addCase(createProject.fulfilled, (state, action) => {
            projectsAdapter.upsertOne(state, action.payload.project);
        });
        builder.addCase(removeProject.fulfilled, (state, action) => {
            projectsAdapter.removeOne(state, action.meta.arg);
        });
        builder.addCase(getProjectTags.fulfilled, (state, action) => {
            const project = state.entities[action.meta.arg];
            projectsAdapter.upsertOne(state, {
                ...project,
                tags: action.payload,
            });
        });
    },
    selectors: {
        selectProjects: (sliceState) => sliceState.entities,
        selectProject: (sliceState, id: number) => {
            return sliceState.entities[id];
        },
    },
});

export type projectsSlice = {
    [projectsSlice.name]: ReturnType<typeof projectsSlice['reducer']>;
};
export const { create, update, remove, clear, add } = projectsSlice.actions;
export const { selectProjects, selectProject } = projectsSlice.selectors;
