import { _delete, get, post, put } from '~/utils/api';
import store, { useAppSelector } from '~/store';
import { META } from '~/const';
import { selectMeta } from '~/store/slices/app/slice';

export const apiLogin = async (data: IRequestData, silentError?: boolean) =>
    await post('auth/login', data, silentError);

export const apiRegister = async (data: IRequestData, silentError?: boolean) =>
    await post('auth/register', data, silentError);

export const apiPasswordRecovery = async (data: IRequestData, silentError?: boolean) =>
    await post('auth/passwordRecovery', data, silentError);

export const apiRefreshToken = async (refreshToken: string) =>
    await post('auth/refresh', { refreshToken }, true);

export const apiLogout = async () => await post('auth/logout');

export const apiGetUser = async (id: number) => await get(`users/${id}`);

export const apiUpdateUser = async (id: number, data: any) => await put(`users/${id}`, data);

export const apiGetMe = async (silentError?: boolean) => await get('me', silentError);

export const apiGetMeta = async (key: string) => await get(`me/meta/${key}`);

export const apiUpdateMeta = async (key: string, data: any) => await post(`me/meta/${key}`, data);

export const apiUpdateAppMeta = async (key: string, data: IAppMeta) =>
    await post(`me/meta/${key}`, data);

export const apiGetUserList = async (offset: number, options: IMeta, groupId?: number) => {
    return await get(
        `users?limit=20&offset=${offset}&filters=${JSON.stringify(
            options.filters,
        )}&orderby=${JSON.stringify(options.orderby)}${groupId ? '&group_id=' + groupId : ''}`,
    );
};

export const apiGetGroup = async (id: number) => await get(`groups/${id}`);

export const apiCreateGroup = async (data: any) => await post('groups', data);

export const apiDeleteGroup = async (id: number) => await _delete(`groups/${id}`);

export const apiUpdateGroup = async (group: IGroup) =>
    await put(`groups/${group.id}`, { ...group });

export const apiGroupInvite = async (groupId: number, data: any) =>
    await post(`groups/${groupId}/invites`, data);

export const apiKickInvite = async (groupId: number, inviteId: number) =>
    await _delete(`groups/${groupId}/invites/${inviteId}`);

export const apiAcceptInvite = async (groupId: number) =>
    await put(`groups/${groupId}/invites/accept`);

export const apiRefuseInvite = async (groupId: number) =>
    await put(`groups/${groupId}/invites/refuse`);

export const apiUpdatePassword = async (password: string) => await put('me/password', { password });

export const apiUpdateAvatar = async (data: FormData) =>
    await post('me/avatar', data, undefined, true);

export const apiUpdateGroupRole = async (groupId: number, userId: number, roleId: number) =>
    await put(`groups/${groupId}/users/${userId}/role`, { group_role_id: roleId });

export const apiConfirmEmail = async (data: { code: string; email: string }) =>
    await post('auth/confirmEmail', data, true);

export const apiSendConfirmEmail = async () => await post('me/confirmEmail');

export const apiResendEmailConfirmation = async (data: any) =>
    await post('auth/resendEmailConfirmation', data);
