import * as React from 'react';
import styles from './Sprints.module.scss';
import { Link } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import Avatar from '~/components/User/Avatar';
import { prepareTimeFromMinutes } from '~/utils/utils';
import { LOADERS, LOADERS_TYPE } from '~/const';
import Circle from '~/components/Loader/Circle/Circle';
import { useAppSelector } from '~/store';
import { selectLoader } from '~/store/slices/app/slice';
import { selectTasks } from '~/store/slices/task/slice';
import { selectUsers } from '~/store/slices/user/slice';
import { selectCurrentSprint } from '~/store/slices/sprint/slice';

interface IExecutiveTime {
    user: IUser;
    difficulty: number;
}

interface IFC {
    sprintId?: number;
    openStatuses?: number[];
}

const SprintTimes = ({ sprintId, openStatuses }: IFC) => {
    const isSprintTasksListLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.SPRINT_TASKS_LIST, LOADERS_TYPE.LOADING),
    );
    const tasks = useAppSelector((state) => selectTasks(state));
    const users = useAppSelector((state) => selectUsers(state));
    const currentSprint = useAppSelector((state) => selectCurrentSprint(state));
    const [executiveTime, setExecutiveTime] = React.useState<IExecutiveTime[]>([]);

    React.useEffect(() => {
        const array: IExecutiveTime[] = [];
        currentSprint.sprintIds.forEach((id) => {
            const task = tasks[id];

            if (!task.status) return;

            if (!openStatuses?.includes(task.status)) return;

            const executive = task.executive ? users[task.executive] : null;

            if (!executive) return;

            array[executive.id] = {
                user: executive,
                difficulty:
                    (array[executive.id]?.difficulty || 0) +
                    (task.timeLeft !== undefined
                        ? task.timeLeft > 0
                            ? task.timeLeft / 60
                            : 15
                        : 0),
            };
        });
        setExecutiveTime(array);
    }, [currentSprint.sprintIds]);

    return (
        <div className={styles.times}>
            {sprintId && isSprintTasksListLoading ? (
                <Circle className={styles.loader} />
            ) : (
                executiveTime.map((data, key) => (
                    <div className={styles.timeLine} key={key}>
                        <Link to={`${urlsMap.userList}/${data.user.id}`}>
                            <Avatar avatar={{ avatar: data.user.avatar, name: data.user.name }} />
                            <span className={styles.name}>{data.user.name}</span>
                        </Link>
                        <span className={styles.difficulty}>
                            {prepareTimeFromMinutes(data.difficulty)}
                        </span>
                    </div>
                ))
            )}
        </div>
    );
};

export default SprintTimes;
