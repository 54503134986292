import * as React from 'react';
import styles from './Auth.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Input from '~/components/Form/Input/Input';
import { BUTTON_SIZE, INPUT_SIZE, INPUT_TYPE } from '~/const';
import { validateEmail, validateRequired } from '~/utils/form';
import Button from '~/components/Button/Button';
import { apiLogin } from '~/api/user';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { useDocumentTitle } from 'usehooks-ts';
import { appHistory } from '~/routes/history';
import store from '~/store';
import { setTokens } from '~/utils/storage';
import { getAccount } from '~/store/slices/account/reducers';

const Login = () => {
    useDocumentTitle('Авторизация');
    const { t } = useTranslation();
    const location = useLocation();

    const handleSubmit = (
        values: Record<string, string>,
        setSubmitting: (isSubmitting: boolean) => void,
        setFieldError: (field: string, message: string) => void,
    ) => {
        const { email, password } = values;
        apiLogin({ email, password }, true)
            .then(async (res) => {
                setTokens(res.accessToken, res.refreshToken, res.socket);
                store.dispatch(getAccount());
                location.state['prevState'] && appHistory.back();
            })
            .catch((error) => {
                setFieldError('error', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className={styles.authContent}>
            <h2>{t('Вас приветствует foach.todo')}</h2>
            <p>{t('Пожалуйста, авторизуйтесь')}</p>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    error: '',
                }}
                onSubmit={(values, { setSubmitting, setFieldError }) =>
                    handleSubmit(values, setSubmitting, setFieldError)
                }
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <Input
                            name="email"
                            label="E-mail адрес"
                            error={errors.email}
                            isTouched={touched.email}
                            validate={validateEmail}
                            size={INPUT_SIZE.BIG}
                            className={styles.inputBlock}
                        />
                        <Input
                            name="password"
                            label="Пароль"
                            error={errors.password}
                            isTouched={touched.password}
                            validate={validateRequired}
                            type={INPUT_TYPE.PASSWORD}
                            size={INPUT_SIZE.BIG}
                            className={styles.inputBlock}
                        />
                        {errors.error && <div className={styles.serverError}>{errors.error}</div>}
                        <Button
                            size={BUTTON_SIZE.BIG}
                            loading={isSubmitting}
                            disabled={!!Object.keys(errors).length}
                        >
                            {t('Войти в систему')}
                        </Button>
                    </Form>
                )}
            </Formik>
            <Link className={styles.resetPasswordLink} to={urlsMap.resetPassword}>
                Забыли пароль?
            </Link>
        </div>
    );
};

export default Login;
