import { createSlice } from '@reduxjs/toolkit';
import { uid } from 'uid';
import { DATE_FORMAT_HHMM, LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import moment from 'moment';
import { getDictionaries, setMeta } from '~/store/slices/app/reducers';
import { getUser, updateUser } from '~/store/slices/user/reducers';
import { updateGroup } from '~/store/slices/account/reducers';
import {
    createSprint,
    getSprint,
    loadBacklogIds,
    updateSprint,
} from '~/store/slices/sprint/reducers';
import { updateTask, updateTaskTiming } from '~/store/slices/task/reducers';
import { updateProject } from '~/store/slices/project/reducers';
import { updatePage } from '~/store/slices/wiki/reducers';

export interface IAppState {
    notificationVisible: boolean;
    visibleGallerySlide: string;
    galleryShown: boolean;
    galleryFiles: IFile[];
    toasts: IToast[];
    dictionaries: IDictionaries;
    loaders: ILoaders;
    popup: IPopup | null;
    meta: IAppMeta;
    tags: ITag[];
    blobs: IBlob;
    firstLoaded: boolean;
}

const initialState: IAppState = {
    notificationVisible: false,
    visibleGallerySlide: '',
    galleryShown: false,
    galleryFiles: [],
    toasts: [],
    dictionaries: {},
    loaders: {
        [LOADERS.TASK_PROJECT_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.TASK_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.PROJECT_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.SPRINT]: {},
        [LOADERS.SPRINT_TASKS_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.SPRINT_BACKLOG_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
            [LOADERS_TYPE.ADDITIONAL_LOADING]: false,
        },
        [LOADERS.USER_LIST]: {
            [LOADERS_TYPE.LOADING]: true,
        },
        [LOADERS.TASK_COMMENT]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.WIKI]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.GROUP]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.GROUP_LIST]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.SET_PASSWORD]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.CONFIRM_EMAIL]: {
            [LOADERS_TYPE.LOADING]: false,
        },
        [LOADERS.SEND_CONFIRM_EMAIL]: {
            [LOADERS_TYPE.LOADING]: false,
        },
    },
    popup: null,
    meta: [],
    tags: [],
    blobs: {},
    firstLoaded: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        clearApp(state) {
            state = { ...initialState };
        },
        storeMeta(state, action) {
            state.meta = {
                ...state.meta,
                ...action.payload,
            };
        },
        setDictionary(state, action) {
            state.dictionaries = {
                ...state.dictionaries,
                ...action.payload,
            };
        },
        setTags(state, action) {
            state.tags = action.payload;
        },
        setPopup(state, action) {
            state.popup = action.payload;
        },
        changeVisibleNotification(state, action) {
            state.notificationVisible = action.payload;
        },
        changeVisibleGallerySlide(state, action) {
            state.visibleGallerySlide = action.payload;
        },
        toggleGallery(state, action) {
            state.galleryShown = action.payload;
        },
        updateGalleryFiles(state, action) {
            state.galleryFiles = action.payload;
        },
        updateLoader(state, action) {
            state.loaders[action.payload.loaderGroup][action.payload.loaderName] =
                action.payload.state;
        },
        addToast(state, action: { payload: IToast; type: string }) {
            state.toasts = [
                ...state.toasts,
                {
                    ...action.payload,
                    id: uid(),
                    visible: true,
                    time: moment().format(DATE_FORMAT_HHMM),
                    viewed: false,
                },
            ];
        },
        removeToast(state, action: { payload: string | undefined }) {
            state.toasts = state.toasts.map((toast: IToast) => {
                if (toast.id === action.payload) {
                    toast.visible = false;
                }

                return toast;
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDictionaries.fulfilled, (state, action) => {
            if (action.payload) {
                state.dictionaries = action.payload;
            }
        });
        builder.addCase(updateUser.pending, (state, action) => {
            Object.keys(action.meta.arg.properties).map((key) => {
                state.loaders[LOADERS.USER_LIST][key] = true;
            });
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            Object.keys(action.meta.arg.properties).map((key) => {
                state.loaders[LOADERS.USER_LIST][key] = false;
            });
        });
        builder.addCase(getUser.pending, (state, action) => {
            state.loaders[LOADERS.USER_LIST][action.meta.arg] = true;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loaders[LOADERS.USER_LIST][action.meta.arg] = false;
        });
        builder.addCase(setMeta.pending, (state) => {
            state.loaders[LOADERS.GROUP_LIST][LOADERS_TYPE.LOADING] = true;
        });
        builder.addCase(setMeta.fulfilled, (state) => {
            state.loaders[LOADERS.GROUP_LIST][LOADERS_TYPE.LOADING] = false;
        });
        builder.addCase(updateGroup.pending, (state) => {
            state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING] = true;
        });
        builder.addCase(updateGroup.fulfilled, (state) => {
            state.loaders[LOADERS.GROUP][LOADERS_TYPE.LOADING] = false;
        });
        builder.addCase(createSprint.pending, (state) => {
            state.loaders[LOADERS.SPRINT][LOADERS_TYPE.LOADING] = true;
        });
        builder.addCase(createSprint.fulfilled, (state) => {
            state.loaders[LOADERS.SPRINT][LOADERS_TYPE.LOADING] = false;
        });
        builder.addCase(updateSprint.pending, (state) => {
            state.loaders[LOADERS.SPRINT][LOADERS_TYPE.LOADING] = true;
        });
        builder.addCase(updateSprint.fulfilled, (state) => {
            state.loaders[LOADERS.SPRINT][LOADERS_TYPE.LOADING] = false;
        });
        builder.addCase(getSprint.pending, (state) => {
            state.loaders[LOADERS.SPRINT_TASKS_LIST][LOADERS_TYPE.LOADING] = true;
        });
        builder.addCase(getSprint.fulfilled, (state) => {
            state.loaders[LOADERS.SPRINT_TASKS_LIST][LOADERS_TYPE.LOADING] = false;
        });
        builder.addCase(updateTask.pending, (state, action) => {
            const key = Object.keys(action.meta.arg.data)[0];
            state.loaders[LOADERS.TASK_LIST][key] = true;
        });
        builder.addCase(updateTask.fulfilled, (state, action) => {
            const key = Object.keys(action.meta.arg.data)[0];
            state.loaders[LOADERS.TASK_LIST][key] = true;
            state.tags = [...state.tags, ...action.payload.task.tags];
        });
        builder.addCase(updateTaskTiming.fulfilled, (state) => {
            state.toasts = [
                ...state.toasts,
                {
                    type: TOAST.SUCCESS,
                    title: 'Запрос отправлен',
                    timer: 3000,
                },
            ];
        });
        builder.addCase(updateProject.pending, (state, action) => {
            const key = Object.keys(action.meta.arg.data)[0];
            state.loaders[LOADERS.PROJECT_LIST][key] = true;
        });
        builder.addCase(updateProject.fulfilled, (state, action) => {
            const key = Object.keys(action.meta.arg.data)[0];
            state.loaders[LOADERS.PROJECT_LIST][key] = false;
        });
        builder.addCase(loadBacklogIds.pending, (state) => {
            state.loaders[LOADERS.SPRINT_BACKLOG_LIST][LOADERS_TYPE.LOADING] = true;
        });
        builder.addCase(loadBacklogIds.fulfilled, (state) => {
            state.loaders[LOADERS.SPRINT_BACKLOG_LIST][LOADERS_TYPE.LOADING] = false;
        });
        builder.addCase(updatePage.pending, (state) => {
            state.loaders[LOADERS.WIKI][LOADERS_TYPE.LOADING] = true;
        });
        builder.addCase(updatePage.fulfilled, (state) => {
            state.loaders[LOADERS.WIKI][LOADERS_TYPE.LOADING] = false;
        });
    },
    selectors: {
        selectTags: (sliceState) => sliceState.tags,
        selectMeta: (sliceState, name) => sliceState.meta[name],
        selectPopup: (sliceState) => sliceState.popup,
        selectNotificationVisible: (sliceState) => sliceState.notificationVisible,
        selectVisibleGallerySlide: (sliceState) => sliceState.visibleGallerySlide,
        selectGalleryShown: (sliceState) => sliceState.galleryShown,
        selectGalleryFiles: (sliceState) => sliceState.galleryFiles,
        selectToasts: (sliceState) => sliceState.toasts,
        selectToastId: (sliceState, id: string | undefined) =>
            sliceState.toasts.find((toast) => toast.id === id),
        selectTaskTypes: (sliceState) => sliceState.dictionaries?.taskTypes ?? {},
        selectProjectsDictionary: (sliceState) => sliceState.dictionaries?.projects ?? {},
        selectProjectTypes: (sliceState) => sliceState.dictionaries?.projectTypes ?? {},
        selectUsersDictionary: (sliceState) => sliceState.dictionaries?.users ?? {},
        selectPriorities: (sliceState) => sliceState.dictionaries?.priorities ?? {},
        selectWdateDictionary: (sliceState) => sliceState.dictionaries?.wdate ?? {},
        selectRoles: (sliceState) => sliceState.dictionaries?.roles ?? {},
        selectLoader: (sliceState, loaderGroup, loaderName) =>
            sliceState.loaders[loaderGroup][loaderName],
        selectLoaders: (sliceState, loadersList) =>
            loadersList.reduce(
                (res: boolean, loader: { group: string; name: string }) =>
                    res || (sliceState.loaders[loader.group][loader.name] ?? false),
                false,
            ),
        selectSubLoaders: (sliceState, loaderGroup) => sliceState.loaders[loaderGroup],
    },
});

export type appSlice = {
    [appSlice.name]: ReturnType<typeof appSlice['reducer']>;
};

export const {
    selectNotificationVisible,
    selectVisibleGallerySlide,
    selectGalleryShown,
    selectGalleryFiles,
    selectToasts,
    selectToastId,
    selectTaskTypes,
    selectProjectsDictionary,
    selectProjectTypes,
    selectUsersDictionary,
    selectPriorities,
    selectWdateDictionary,
    selectRoles,
    selectLoader,
    selectLoaders,
    selectSubLoaders,
    selectPopup,
    selectMeta,
    selectTags,
} = appSlice.selectors;

export const {
    changeVisibleNotification,
    changeVisibleGallerySlide,
    toggleGallery,
    updateGalleryFiles,
    addToast,
    removeToast,
    updateLoader,
    setPopup,
    setTags,
    setDictionary,
    storeMeta,
    clearApp,
} = appSlice.actions;
