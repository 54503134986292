import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    createSprint,
    getSprint,
    loadBacklogIds,
    updateSprint,
} from '~/store/slices/sprint/reducers';

export const sprintsAdapter = createEntityAdapter<ISprint>();

export interface ISprintState {
    loadedIds: number[];
    entities: Record<number, ISprint[]>;
    totalCount: 0;
    sprint: {
        pid?: number;
        backlogIds: number[];
        totalCount: number;
        sid?: number;
        sprintIds: number[];
    };
}

export const sprintsSlice = createSlice({
    name: 'sprints',
    initialState: {
        ...sprintsAdapter.getInitialState(),
        totalCount: 0,
        viewedCount: 0,
        sprint: {
            pid: null,
            backlogIds: [],
            totalCount: 0,
            sid: null,
            sprintIds: [],
        },
    },
    reducers: {
        create: sprintsAdapter.setMany,
        add: sprintsAdapter.addMany,
        update: sprintsAdapter.upsertMany,
        remove: sprintsAdapter.removeMany,
        clear: sprintsAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(createSprint.fulfilled, (state, action) => {
            sprintsAdapter.upsertOne(state, { ...action.payload.sprint });
        });
        builder.addCase(getSprint.fulfilled, (state, action) => {
            sprintsAdapter.upsertOne(state, { ...action.payload.sprint });
        });
        builder.addCase(updateSprint.fulfilled, (state, action) => {
            sprintsAdapter.upsertOne(state, { ...action.payload.sprint });
        });
        builder.addCase(loadBacklogIds.fulfilled, (state, action) => {
            if (action.payload) {
                state.sprint.backlogIds = action.payload.rows.map((task: ITask) => task.id);
                state.sprint.totalCount = action.payload.totalCount;
            }
        });
    },
    selectors: {
        selectSprints: (sliceState) => sliceState.entities,
        selectSprint: (sliceState, id: number) => sliceState.entities[id],
        selectBacklogIds: (sliceState) => sliceState.sprint.backlogIds,
        selectBacklogTotalCount: (sliceState) => sliceState.sprint.totalCount,
        selectCurrentSprint: (sliceState) => sliceState.sprint,
    },
});

export type tasksSlice = {
    [sprintsSlice.name]: ReturnType<typeof sprintsSlice['reducer']>;
};
export const { create, update, remove, clear, add } = sprintsSlice.actions;
export const {
    selectSprints,
    selectSprint,
    selectBacklogIds,
    selectBacklogTotalCount,
    selectCurrentSprint,
} = sprintsSlice.selectors;
