import * as React from 'react';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { appHistory } from '~/routes/history';
import Layout from '~/containers/Layout/Layout';
import OtherRoutes from '~/routes/OtherRoutes';
import Auth from '~/containers/Auth/Auth';
import Login from '~/containers/Auth/Login';
import Register from '~/containers/Auth/Register';
import ResetPassword from '~/containers/Auth/ResetPassword';
import ResetPasswordSuccess from '~/containers/Auth/ResetPasswordSuccess';
import PrivateRoutes from '~/routes/PrivateRoutes';
import ConfirmEmail from '~/containers/Auth/ConfitmEmail/ConfirmEmail';
import Popup from '~/components/Popup/Popup';
import Manager from '~/components/Popup/Manager';
import Toasts from '~/containers/Toasts/Toasts';
import Gallery from '~/components/Gallery/Gallery';
import store, { useAppSelector } from '~/store';
import { selectGalleryShown, selectPopup } from '~/store/slices/app/slice';
import { setTokens } from '~/utils/storage';
import { getAccount } from '~/store/slices/account/reducers';
import { selectIsLogging } from '~/store/slices/account/slice';
import { shallowEqual } from 'react-redux';
import { Suspense } from 'react';

const Tasks = React.lazy(() => import('~/containers/Tasks/Tasks'));
const Logs = React.lazy(() => import('~/containers/Tasks/Logs/Logs'));
const Timings = React.lazy(() => import('~/containers/Tasks/Timings/Timings'));
const CreateTask = React.lazy(() => import('~/containers/Tasks/CreateTask/CreateTask'));
const Projects = React.lazy(() => import('~/containers/Projects/Projects'));
const CreateProject = React.lazy(() => import('~/containers/Projects/Create/Create'));
const Users = React.lazy(() => import('~/containers/Users/Users'));
const Online = React.lazy(() => import('~/containers/Online/Online'));
const Routing = () => {
    const urlParams = new URL(window.location.href).searchParams;
    const token = urlParams.get('token') || urlParams.get('invite');
    const galleryShown = useAppSelector((state) => selectGalleryShown(state), shallowEqual);
    const isLogging = useAppSelector((state) => selectIsLogging(state), shallowEqual);
    const popup = useAppSelector((state) => selectPopup(state), shallowEqual);
    const accessToken = localStorage.getItem('accessToken') ?? '';
    const refreshToken = localStorage.getItem('refreshToken') ?? '';
    const socket = localStorage.getItem('socket') ?? '';

    React.useEffect(() => {
        if (token) {
            setTokens(token, token, token);
        } else {
            setTokens(accessToken, refreshToken, socket);
        }
        store.dispatch(getAccount());
    }, []);

    if (isLogging) return null;

    return (
        <HistoryRouter history={appHistory}>
            <Suspense fallback={<div>Загрузка...</div>}>
                <Routes>
                    <Route element={<OtherRoutes />}>
                        <Route path={urlsMap.index} element={<Auth />}>
                            <Route path={urlsMap.index} element={<Login />} />
                            <Route path={urlsMap.register} element={<Register />} />
                            <Route path={urlsMap.resetPassword} element={<ResetPassword />} />
                            <Route
                                path={urlsMap.resetPasswordSuccess}
                                element={<ResetPasswordSuccess />}
                            />
                        </Route>
                    </Route>
                    <Route element={<PrivateRoutes />}>
                        <Route path={urlsMap.index} element={<Layout />}>
                            <Route path={`${urlsMap.taskList}/*`} element={<Tasks />} />
                            <Route
                                path={`${urlsMap.taskList}/:id${urlsMap.logs}`}
                                element={<Logs />}
                            />
                            <Route
                                path={`${urlsMap.taskList}/:id${urlsMap.timings}`}
                                element={<Timings />}
                            />
                            <Route path={urlsMap.createTask} element={<CreateTask />} />
                            <Route path={`${urlsMap.projectList}/*`} element={<Projects />} />
                            <Route path={urlsMap.createProject} element={<CreateProject />} />
                            <Route path={`${urlsMap.userList}/*`} element={<Users />} />
                            <Route path={`${urlsMap.online}`} element={<Online />} />
                            <Route path="*" element={<Navigate to={urlsMap.taskList} />} />
                        </Route>
                    </Route>
                    <Route path={urlsMap.emailConfirm} element={<ConfirmEmail />} />
                    <Route path={`${urlsMap.inviteGroup}/:id`} element={<ConfirmEmail />} />
                    <Route path={`${urlsMap.inviteProject}/:id`} element={<ConfirmEmail />} />
                </Routes>
                {popup && (
                    <Popup wrapper={popup.wrapper} withoutClose={popup.withoutClose}>
                        <Manager name={popup.name} data={popup.data} />
                    </Popup>
                )}
                <Toasts />
                {galleryShown && (
                    // TODO: move Gallery out from files. Should be somewhere else.
                    <Gallery />
                )}
            </Suspense>
        </HistoryRouter>
    );
};

export default Routing;
