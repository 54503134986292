import * as React from 'react';
import styles from './TracksTime.module.scss';
import { urlsMap } from '~/utils/urls';
import { Link } from 'react-router-dom';
import { prepareTimeFromMinutes } from '~/utils/utils';
import classNames from 'classnames';

interface IFC {
    taskId: number;
    taskTitle: string;
    taskTime: number;
    width: number;
    left: number;
    bg: string;
    hour: number;
}

const Track = ({ width, left, bg, taskId, taskTitle, taskTime, hour }: IFC) => {
    return (
        <Link
            className={styles.track}
            to={`${urlsMap.taskList}/${taskId}`}
            onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            style={{
                width: `${width}%`,
                left: `${left}%`,
                background: bg,
            }}
        >
            <span
                className={classNames(styles.toolTip, {
                    [styles.left]: hour <= 3,
                    [styles.right]: hour >= 22,
                })}
            >
                <span className={styles.toolTipContent}>
                    #{taskId} {taskTitle} [{prepareTimeFromMinutes(taskTime)}]
                </span>
            </span>
        </Link>
    );
};

export default Track;
