import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetUser, apiUpdateUser } from '~/api/user';

const updateUser = createAsyncThunk(
    'user/update',
    async (data: { properties: { [field: string]: any }; userId: number }) => {
        return await apiUpdateUser(data.userId, data.properties);
    },
);

const getUser = createAsyncThunk('user/add', async (id: number) => {
    return await apiGetUser(id);
});

export { updateUser, getUser };
