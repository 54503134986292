import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetCalendar } from '~/api/calendar';

const getCalendar = createAsyncThunk('calendar/add', async () => {
    return await apiGetCalendar();
});

// const getCalendarDay  = createAsyncThunk('tasks/add', async () => {
//     return await apiGetCalendarDay();
// });

export { getCalendar };
