import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    createPage,
    getWiki,
    loadPage,
    removePage,
    updatePage,
} from '~/store/slices/wiki/reducers';

export const wikiAdapter = createEntityAdapter<IWikiPage>();

export interface IWikiState {
    entities: Record<number, IWikiPage[]>;
    totalCount: 0;
}

export const wikiSlice = createSlice({
    name: 'wiki',
    initialState: {
        ...wikiAdapter.getInitialState(),
        totalCount: 0,
    },
    reducers: {
        create: wikiAdapter.setMany,
        add: wikiAdapter.addMany,
        update: wikiAdapter.upsertMany,
        remove: wikiAdapter.removeMany,
        clear: wikiAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(removePage.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.removeOne(state, action.meta.arg);
            }
        });
        builder.addCase(loadPage.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.upsertOne(state, action.payload.page);
            }
        });
        builder.addCase(createPage.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.upsertOne(state, action.payload.page);
            }
        });
        builder.addCase(updatePage.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.upsertOne(state, action.payload.page);
            }
        });
        builder.addCase(getWiki.fulfilled, (state, action) => {
            if (action.payload) {
                wikiAdapter.upsertMany(state, action.payload.pages);
            }
        });
    },
    selectors: {
        selectTotalCount: (sliceState) => sliceState.totalCount,
        selectWikiPage: (sliceState, id: number) => sliceState.entities[id],
    },
});

export type wikiSlice = {
    [wikiSlice.name]: ReturnType<typeof wikiSlice['reducer']>;
};

export const { create, update, remove, clear, add } = wikiSlice.actions;
export const { selectTotalCount, selectWikiPage } = wikiSlice.selectors;
