import * as React from 'react';
import styles from './Param.module.scss';
import classNames from 'classnames';

interface IParam {
    label: string;
    children?: React.ReactChild | React.ReactChild[];
    className?: string;
    withoutBorder?: boolean;
}

const Param = ({ label, children, className, withoutBorder }: IParam) => {
    return (
        <label
            className={classNames(styles.param, className, {
                [styles.withoutBorder]: withoutBorder,
            })}
        >
            {label && <div className={styles.label}>{label}:</div>}
            {children}
        </label>
    );
};

export default Param;
