import * as React from 'react';
import styles from './Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, LOADERS, LOADERS_TYPE, TOAST } from '~/const';
import CreatableInputField from '~/components/Form/Select/CreatableInput';
import { apiGroupInvite } from '~/api/user';
import { addToast, selectLoader, setPopup, updateLoader } from '~/store/slices/app/slice';
import store, { useAppSelector } from '~/store';
import { add as addUsers } from '~/store/slices/user/slice';
import { addUserListIds } from '~/store/slices/userList/slice';

interface IFC {
    groupId: number;
}

const Invite = ({ groupId }: IFC) => {
    const [emails, setEmails] = React.useState<string[]>([]);
    const isGroupLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.GROUP, LOADERS_TYPE.LOADING),
    );

    const closeHandler = () => {
        store.dispatch(setPopup(null));
    };

    const inviteHandler = () => {
        store.dispatch(
            updateLoader({
                loaderGroup: LOADERS.GROUP,
                loaderName: LOADERS_TYPE.LOADING,
                state: true,
            }),
        );
        apiGroupInvite(groupId, { emails })
            .then((res) => {
                store.dispatch(addUsers(res.relations));
                store.dispatch(addUserListIds(res.relations.map((user: IUser) => user.id)));
                closeHandler();
                store.dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: 'Новые пользователи приглашены в группу',
                        timer: 3000,
                    }),
                );
            })
            .finally(() => {
                store.dispatch(
                    updateLoader({
                        loaderGroup: LOADERS.GROUP,
                        loaderName: LOADERS_TYPE.LOADING,
                        state: false,
                    }),
                );
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Приглашение пользователей</div>
            <CreatableInputField
                label="Введите email-адреса сотрудников"
                className={styles.select}
                classNameWrapper={styles.selectWrapper}
                changeHandler={(value) => setEmails(value)}
            />
            <div className={styles.buttons}>
                <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                    Отмена
                </Button>
                <Button
                    color={BUTTON_COLOR.BLUE}
                    disabled={emails.length < 1}
                    onClick={inviteHandler}
                    loading={isGroupLoading}
                >
                    Пригласить
                </Button>
            </div>
        </div>
    );
};

export default Invite;
