import * as React from 'react';
import styles from './TaskType.module.scss';
import classNames from 'classnames';
import Tooltip from '~/components/Tooltip/Tooltip';
import { useAppSelector } from '~/store';
import { selectTaskTypes } from '~/store/slices/app/slice';

interface IFC {
    type?: number | string;
    className?: string;
    withoutTooltip?: boolean;
}

const TaskType = ({ type, className, withoutTooltip }: IFC) => {
    const taskTypes = useAppSelector((state) => selectTaskTypes(state));

    const element = <div className={classNames(styles.type, `type${type}`, className)}></div>;

    if (!withoutTooltip) {
        return <Tooltip text={type ? taskTypes?.[type] : 'Без типа'}>{element}</Tooltip>;
    }

    return element;
};

export default TaskType;
