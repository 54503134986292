import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getCalendar } from '~/store/slices/calendar/reducers';

export const calendarAdapter = createEntityAdapter<ICalendarDay>();

export interface ICalendarState {
    ids: number[];
    entities: Record<number, ICalendarDay[]>;
    userNorm: number;
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: {
        ...calendarAdapter.getInitialState(),
        userNorm: 0,
    },
    reducers: {
        create: calendarAdapter.setMany,
        add: calendarAdapter.addMany,
        update: calendarAdapter.upsertMany,
        remove: calendarAdapter.removeMany,
        clear: calendarAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(getCalendar.fulfilled, (state, action) => {
            console.log(action.payload);
            if (action.payload) {
                calendarAdapter.addMany(state, action.payload.data.calendar);
                state.userNorm = action.payload.userNorm;
            }
        });
    },
    selectors: {
        selectCalendarUserNorm: (sliceState) => sliceState.userNorm,
        selectCalendar: (sliceState) => sliceState.ids.map((id) => sliceState.entities[id]),
    },
});

export type calendarSlice = {
    [calendarSlice.name]: ReturnType<typeof calendarSlice['reducer']>;
};
export const { create, update, remove, clear, add } = calendarSlice.actions;
export const { selectCalendar, selectCalendarUserNorm } = calendarSlice.selectors;
