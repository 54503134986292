import * as React from 'react';
import moment, { Moment, DurationInputArg2 } from 'moment';
import { timeDiff } from '~/containers/Tasks/Calendar/Calendar';
import { DATE_FORMAT, DATE_FORMAT_HHMM, SERVER_DATE_FORMAT, TASK_CONTROLS } from '~/const';

export const deleteMascot = () => {
    const loader = document.getElementById('loader');

    if (loader) {
        loader.classList.add('remove');
        const timer = setTimeout(() => {
            loader.remove();
            clearTimeout(timer);
        }, 300);
    }
};

export const getColor = (str: string) => {
    if (!str) {
        return;
    }

    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        sum += charCode;
    }
    return 'color' + (sum % 11);
};

export const multipleInArray = (arr: string[], values: string[]) => {
    return values.every((value) => {
        return arr.includes(value);
    });
};

export const prepareTimeFromMinutes = (time?: number) => {
    if (time === undefined) return null;

    if (isNaN(time)) {
        return null;
    }

    const addMinus = time < 0;
    time = Math.abs(time);

    const hours = Math.trunc(time / 60);
    const minutes = Math.trunc(time % 60);
    return (
        (addMinus ? '-' : '') +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        String(minutes).padStart(2, '0')
    );
};

export const prepareHMFromSeconds = (time: number) => {
    const hours = Math.trunc(time / 3600);
    const minutes = Math.trunc((time - hours * 3600) / 60);
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
};

export const prepareHMSFromSeconds = (time: number) => {
    const hours = Math.trunc(time / 3600);
    const minutes = Math.trunc((time - hours * 3600) / 60);
    const seconds = Math.trunc(time - (hours * 3600 + minutes * 60));
    return (
        String(hours).padStart(2, '0') +
        ':' +
        String(minutes).padStart(2, '0') +
        ':' +
        String(seconds).padStart(2, '0')
    );
};

export const prepareDateToLocal = (date: string | null, format: string, withTime?: boolean) => {
    if (!date) {
        return '';
    }

    return moment(date, withTime ? '' : SERVER_DATE_FORMAT).format(format);
};

export const prepareDateToServer = (date: string, format?: string, withTime?: boolean) => {
    if (!date) {
        return '';
    }

    const innerMoment = moment(date, format ?? DATE_FORMAT_HHMM);

    return withTime ? innerMoment.toISOString() : innerMoment.format(SERVER_DATE_FORMAT);
};

export const prepareDateToFormat = (date?: string, format?: string) => {
    if (!date) {
        return '';
    }

    return moment(date).format(format);
};

export const dateToday = (date: string | null) => {
    if (!date) {
        return false;
    }

    return moment(date, DATE_FORMAT).isSame(moment().format(DATE_FORMAT));
};

export const dateOverdue = (date?: string | null) => {
    if (!date) {
        return false;
    }

    return moment(date, DATE_FORMAT).isBefore(moment(), 'days');
};

export const dateFuture = (date?: string | null) => {
    if (!date) {
        return false;
    }

    return moment(date, DATE_FORMAT).isAfter(moment());
};

export const prepareOptions = (options: ISelectOption) => {
    const array = Object.keys(options).map((key) => {
        return { value: key, label: options[key] };
    });

    const empty = array.findIndex((elem) => elem.value === '');

    if (empty !== -1) {
        array.splice(0, 0, array.splice(empty, 1)[0]);
    }

    return array;
};

export const prepareFilesToServer = (files: FileList | null) => {
    if (!files) {
        return;
    }

    const filesData = new FormData();

    for (let i = 0; i < files.length; i++) {
        filesData.append('file[]', files[i]);
    }

    return filesData;
};

export const prepareText = (text: string) => {
    const regExp = /(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim;
    return text.replace(regExp, (url: string) => {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
};

export const getTasksByIds = (
    tasks: ITaskEntities,
    ids: number[],
    excludeSprintId?: number,
    onlyThisStatuses?: number[],
) => {
    return ids
        .map(
            (id) =>
                Object.values(tasks).filter((task) => {
                    if (excludeSprintId) {
                        return task.id === id && task.sprint_id !== excludeSprintId;
                    }

                    if (onlyThisStatuses) {
                        return (
                            task.id === id && task.status && onlyThisStatuses.includes(task.status)
                        );
                    }

                    return task.id === id;
                })[0],
        )
        .filter((element) => element !== undefined);
};

export const difficultyToMs = (difficulty?: number) => {
    return (difficulty || 15) * 60 * 1000;
};

export const generateRangeArray = (
    length: number,
    startDate: Moment,
    format: string,
    includes?: boolean,
) => {
    const rangeArray: any = [];

    for (let i = 0; i < length; i++) {
        const day = moment(startDate).add(i, format as DurationInputArg2);

        if (includes) {
            if (!rangeArray.includes(day.week())) {
                rangeArray.push(day.week());
            }
        } else {
            rangeArray.push(day);
        }
    }

    return rangeArray;
};

export const generateTrackTask = (
    timings: ITiming[] | undefined,
    newTiming: ITiming[],
    type: string,
) => {
    newTiming = newTiming.map((timing) => {
        return {
            ...timing,
            taskid: Number(timing.taskid),
        };
    });
    if (!!timings?.length) {
        switch (type) {
            case TASK_CONTROLS.START:
                return [...timings, ...newTiming];

            case TASK_CONTROLS.STOP:
                return timings.map((timing) =>
                    timing.id === newTiming[0].id
                        ? {
                              ...timing,
                              ...newTiming[0],
                          }
                        : timing,
                );

            default:
                return [...timings];
        }
    } else {
        return [...newTiming];
    }
};

export const clickableLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
    window.location.href = link;
    e.preventDefault();
};

export const strSanitize = (value: string) => {
    return value.replace(/[^A-Za-zА-Яа-я0-9]+/g, '');
};

export const getDateName = (date: string) => {
    const dateWithFormat = moment(date).format(DATE_FORMAT);

    if (dateWithFormat === moment().format(DATE_FORMAT)) {
        return 'Сегодня';
    }

    if (dateWithFormat === moment().add(1, 'days').format(DATE_FORMAT)) {
        return 'Завтра';
    }

    if (dateWithFormat === moment().add(-1, 'days').format(DATE_FORMAT)) {
        return 'Вчера';
    }

    return dateWithFormat;
};

export const groupTasksProject = (tasks: ITask[]) => {
    return tasks.reduce((group: any, arr) => {
        const { pid } = arr;

        if (!pid) return group;

        group[pid] = group[pid] ?? [];

        group[pid].push(arr);

        return group;
    }, {});
};

export const getTracksUserDay = (
    day: moment.Moment,
    tasks: ITaskEntities,
    accountId?: number,
    userId?: number,
) => {
    const tasksGetDayData: ITask[] = [];
    const taskIds: number[] = [];
    let time = 0;

    Object.values(tasks).forEach((task) => {
        const currentTask = { ...task };
        const addedTimings: ITiming[] = [];

        if (task.timings) {
            task.timings.forEach((timing) => {
                if (timing.userid === userId || (accountId === userId && !timing.userid)) {
                    addedTimings.push(timing);

                    if (
                        moment(timing.stime).isSame(day, 'dates') &&
                        moment(timing.ftime).isSame(day, 'dates')
                    ) {
                        time += timeDiff(timing.stime, timing.ftime);
                    } else if (
                        moment(timing.stime).isSame(day, 'dates') &&
                        moment(timing.ftime).isAfter(day, 'dates')
                    ) {
                        time += timeDiff(
                            moment(timing.stime),
                            moment(day, DATE_FORMAT_HHMM).hours(24).minutes(0).seconds(0),
                        );
                    } else if (
                        moment(timing.stime).isBefore(day, 'dates') &&
                        moment(timing.ftime).isSame(day, 'dates')
                    ) {
                        time += timeDiff(
                            moment(day, DATE_FORMAT_HHMM).hours(0).minutes(0).seconds(0),
                            timing.ftime,
                        );
                    } else if (moment(timing.stime).isSame(day, 'dates') && !timing.ftime) {
                        time += timeDiff(timing.stime, moment());
                    }

                    if (
                        moment(day).isSame(timing.stime, 'days') ||
                        moment(day).isSame(timing.ftime, 'days')
                    ) {
                        if (taskIds.includes(task.id)) {
                            return;
                        }
                        taskIds.push(task.id);
                        currentTask.timings = addedTimings;
                        tasksGetDayData.push(currentTask);
                    }
                }
            });
        }
    });

    return {
        tasks: tasksGetDayData,
        timeText: prepareHMFromSeconds(time / 1000),
        time: time,
    };
};

export const getNoun = (number: number, one: string, two: string, five: string) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};
